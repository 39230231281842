import axios from 'axios'
import { host } from '../../helper/config'

const PLUGINS_NAME = 'admin-system'

export const setSystemMaintained = async (token, isMaintained) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/set-system-maintained`, {
        status: isMaintained ? 1 : 0
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const getSystemMaintained = async (token) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-system-maintained-state`, {
        
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data.isMaintained
    }
    return true
}

export const enableTicketRoll = async (token, gameId, isClassic) => {
    const response = await axios.post(`${host}/system-ticket-roll/enable-roll`, {
        gameId: gameId,
        isClassic: isClassic
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data.rolledMethod
    }
    return true
}

export const disableTicketRoll = async (token, gameId, isClassic) => {
    const response = await axios.post(`${host}/system-ticket-roll/disable-roll`, {
        gameId: gameId,
        isClassic: isClassic
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

import React from 'react'

export const TableRow = (props) => {
  const { rIndex, id, channel, numbers, amount, resultDate, isSingle } = props;

  return (
    <tr>
      <td className='text-center fw-bold'>{rIndex + 1}</td>
      <td>{id}</td>
      <td className='text-center'>
        {resultDate}
      </td>
      <td className='text-center'>
        {channel}
      </td>
      <td style={{ fontSize: 25 }} className='text-center text-danger fw-bold'>{numbers.split('').join(' ')}</td>
      <td className='text-center text-success fw-bold'>{isSingle ? amount : 'CÒN'}</td>
    </tr>
  );
};

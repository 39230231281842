import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Col, Row, Card, Table, Button} from '@themesberg/react-bootstrap'
import { useCookies } from 'react-cookie'
import TableRow from '../component/TableRow'
import { calculatePage } from '../../../../helper/helper'
import Pagination from '../../../../components/Pagination'
import { Context } from '../TicketStorage'
import { getListClassicInputTicket, deleteAllTicket } from '../../../../controller/api/__classic__/ticketStorage'

const _Table = () => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [data, setData] = useState({})

  const [page, setPage] = useState(0)

  const status = useRef(0)

  const getAllFunc = useCallback(async () => {
    const response = await getListClassicInputTicket(cookies.lottmekong, {
      inputId: state.inputId,
    }, page)

    if (response) {
      if (response.data.length > 0) {
        status.current = response.data[0].status
      }
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }
      setData(res || {})
    }
  }, [cookies.lottmekong, state.inputId, page])

  useEffect(() => {
    if (state.inputId !== '') {
      getAllFunc()
    }
  }, [getAllFunc, state.inputId])

  return (
    <>
      {status.current === 0 && <Button className='mb-3' style={{ marginRight: 20 }} variant="warning" type="submit" onClick={() => { let result = deleteAllTicket(cookies.lottmekong,state.inputId) 
          if(result != null)
          {
            window.location.reload()
          }
      }}>Xóa tất cả</Button>
      }
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th scope="col">STT</th>
              <th scope="col">Dãy số</th>
              <th scope="col">Số lượng nhập</th>
              <th scope="col">Kỳ quay số</th>
              <th scope="col">Ngày</th>
              {status.current === 0 &&
                <th scope="col" style={{ width: 300 }}>Thao tác</th>
              }
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map((pv, index) => <TableRow cookies={cookies} rIndex={index} key={`table-keys-lottmekong-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={data.totalPage || 0}
                currentPage={page || 0}
                onPageChange={(i) => {
                  setPage(i)
                }}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default React.memo(_Table)
import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { value, user, createdAt, reason } = props;



  return (
    <tr>
      <td>
        {(user && user.name) || ""}<br />
        <span><strong className={`text-primary`}>{(user && user.phone.replace('+84', '0')) || ""}</strong></span>
      </td>
      <td className="fw-900 text-success text-right">{new Intl.NumberFormat('vn-VI', {}).format(value) || ""}</td>
      <td>
      {reason === 'CUSTOMER_REQUIRED' ? 'Khách hàng yêu cầu' : reason === 'WRONG_CHARGE' ? 'Nhầm lẫn trong lúc nạp' : reason}
      </td>
      <td className="text-center">{moment(createdAt || '').format('DD/MM/YYYY HH:mm:ss')}</td>
    </tr>
  );
};

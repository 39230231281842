import React from "react";
import { Button, Modal } from '@themesberg/react-bootstrap';
import RewardTable from './partials/reward-table'

export default (props) => {

  const reward = props.reward

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: 'normal', fontSize: 15 }} id="contained-modal-title-vcenter">
          Chi tiết giải thưởng {reward.ticketId && 'vé: ' + reward.ticketId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {reward.ticket &&
          <RewardTable
            rewards={reward.ticket.rewards}
            rewardNumber={reward.ticket.rewardNumbers}
          />
        }
        {reward.ticket &&
          <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Tiền thưởng:</div>
              <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(reward.grossValue / reward.ticket.amount) || ""}</strong></div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Thuế:</div>
              <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(reward.tax / reward.ticket.amount) || ""}</strong></div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Phí:</div>
              <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(reward.fee / reward.ticket.amount) || ""}</strong></div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Tiền thưởng thực nhận:</div>
              <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(reward.netValue / reward.ticket.amount) || ""}</strong></div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>x Số tờ:</div>
              <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(reward.ticket.amount) || ""}</strong></div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Tổng tiền thưởng:</div>
              <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(reward.netValue) || ""}</strong></div>
            </div>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

import axios from 'axios'
import { host } from '../../helper/config'
import { ITEM_PER_PAGE } from '../../helper/helper'

const PLUGINS_NAME = 'admin-refund'

// GET LIST REFUND
export const getListRefund = async (token, query, page) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/get-list-refund`,
        {
            ...query,
            page: page,
            itemPerPage: ITEM_PER_PAGE
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const getSummaryRefund = async (token, query) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-summary-refund`, {
        ...query
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const getCsvRefund = async (token, query, page, totalPage) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-csv-refund`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE,
        totalPage: totalPage
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })
    
    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

// ADD REFUND
export const addRefund = async (token, value, reason, phone) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/add-refund`,
        {
            value: value,
            reason: reason,
            phone: phone
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data
    }
    return null
}

import { Button, Form } from '@themesberg/react-bootstrap';
import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react'
import { updateClassicInputTicket, deleteTicket, updateStatusTicket } from '../../../../controller/api/__classic__/ticketStorage';

const TableRow = (props) => {
  const { rIndex, id, channelName, createdAt, numbers, inputAmount, resultDate, status, isSingle, cookies } = props;

  const datedString = moment(createdAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()

  const [isUpdating, setIsUpdating] = useState(false)

  const numbersRef = useRef(null);
  const amountRef = useRef(null);

  const currentAmountRef = useRef(inputAmount);
  const currentNumbersRef = useRef(numbers);

  const onCancel = useCallback(() => {
    setIsUpdating(false)
  }, [])

  const onSubmit = useCallback(async () => {
    try {
      const resp = await updateClassicInputTicket(cookies.lottmekong, id, numbersRef.current.value, isSingle ? parseInt(amountRef.current == null ? inputAmount : amountRef.current.value) : 110, isSingle)

      if (resp) {
        currentNumbersRef.current = numbersRef.current.value
        currentAmountRef.current = isSingle ? amountRef.current == null ? inputAmount : amountRef.current.value : currentAmountRef.current
        setIsUpdating(false)
      } else {
        alert('Có lỗi xảy ra')
        return false
      }
    } catch (e) {
      alert(e)
    }
  }, [cookies.lottmekong, id, isSingle])

  if (isUpdating) {
    return (
      <tr>
        
        <td className='text-center fw-bold'>{rIndex + 1}</td>
        <td style={{ fontSize: 25 }} className='text-center text-danger fw-bold'><Form.Control defaultValue={currentNumbersRef.current} ref={numbersRef} type="text" /></td>
        {status === 0 ?
          <td className='text-center fw-bold'><Form.Control defaultValue={currentAmountRef.current} ref={amountRef} type="text" /></td>
          :
          <td className='text-center fw-bold'>{currentAmountRef.current}</td>
        }
        <td className='text-center'>
          {channelName} <br />
          {resultDate}
        </td>
        <td className='text-center'>{datedString}</td>
        <td className='text-center fw-bold'>
          <Button variant='danger' onClick={onCancel}>
            Hủy
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='success' onClick={onSubmit}>
            Xong
          </Button>
        </td>
      </tr>
    )
  }

  return (
    <tr>
      <td className='text-center fw-bold'>{rIndex + 1}</td>
      <td style={{ fontSize: 25 }} className='text-center text-danger fw-bold'>{currentNumbersRef.current.split('').join(' ')}</td>
      <td className='text-center fw-bold'>{currentAmountRef.current}</td>
      <td className='text-center'>
        {channelName} <br />
        {resultDate}
      </td>
      <td className='text-center'>{datedString}</td>
      <td>
        {status === 0 &&
          <Button variant='warning' onClick={() => {
            let result = deleteTicket(cookies.lottmekong, id)
            if (result != null) {
              window.location.reload()
            }
          }}>
            Xóa
          </Button>
        }
        &nbsp;&nbsp;&nbsp;&nbsp;
        {(status === 0 || status === 6) &&
          <Button variant='warning' onClick={() => { setIsUpdating(true) }}>
            Sửa
          </Button>
        }
        &nbsp;&nbsp;&nbsp;&nbsp;
        {(status === 1) &&
            <Button variant='warning' onClick={async () => { let result = await updateStatusTicket(cookies.lottmekong,id,6) 
              if(result != null)
              {
                window.location.reload()
              }
              }}>
              Tạm ngưng
            </Button>
        }
        &nbsp;&nbsp;&nbsp;&nbsp;
        {(status === 6) &&
            <Button variant='warning' onClick={async () => { let result = await updateStatusTicket(cookies.lottmekong,id,1) 
              if(result != null)
              {
                window.location.reload()
              }
              }}>
              Hoạt động
            </Button>
        }
      </td>
    </tr>
  );
};

export default React.memo(TableRow)
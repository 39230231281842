import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap'
import { getIssueList, changeToCurrentPeriod, deleteTicket } from '../../../../controller/api/ticket'
import { useCookies } from 'react-cookie'
import { TableRow } from '../component/TableRow'
import { calculatePage } from '../../../../helper/helper'
import Pagination from '../../../../components/Pagination'
import { Context } from '../IssueTicket'
import TicketDetail from "../sub-screen/ticket-detail/TicketDetail"

const _Table = () => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [data, setData] = useState({})

  const [page, setPage] = useState(0)

  const [modalShow, setModalShow] = useState(false)

  const numberRowsRef = useRef([])

  const ticketIdRef = useRef('')

  const gameIdRef = useRef('')

  const statusRef = useRef(0)

  const getAllFunc = useCallback(async () => {
    let phone = state.phone

    if (phone.indexOf('MB') < 0) {
      phone = phone.replace('0', '+84')
    }
    const response = await getIssueList(cookies.lottmekong, {
      from: state.from,
      to: state.to,
      phone: phone,
      gameId: state.gameId,
    }, page)

    if (response) {
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }
      setData(res || {})
    }
  }, [cookies.lottmekong, state.from, state.to, state.phone, state.gameId, page])

  const deleteTicketFunc = useCallback(async () => {
    const response = await deleteTicket(cookies.lottmekong, ticketIdRef.current)

    if (response && response.status === 'success') {
      let newData = JSON.parse(JSON.stringify(data))

      newData.list = newData.list.filter(it => it.id !== ticketIdRef.current)

      newData.total -= 1

      newData.totalPage = calculatePage(newData.total)

      setData(newData)

      setModalShow(false)
    }
  }, [cookies.lottmekong, data])

  const changePeriodFunc = useCallback(async () => {
    const response = await changeToCurrentPeriod(cookies.lottmekong, ticketIdRef.current, gameIdRef.current)

    if (response && response.status === 'success') {
      let newData = JSON.parse(JSON.stringify(data))

      newData.list = newData.list.filter(it => it.id !== ticketIdRef.current)

      newData.total -= 1

      newData.totalPage = calculatePage(newData.total)

      setData(newData)

      setModalShow(false)
    }
  }, [cookies.lottmekong, data])

  const onShowDetailPress = (ticketId, numberRows, gameId, status) => {
    numberRowsRef.current = numberRows
    ticketIdRef.current = ticketId
    gameIdRef.current = gameId
    statusRef.current = status
    setModalShow(true)
  }

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th scope="col">Thao tác</th>
              <th scope="col">Mã vé</th>
              <th scope="col">Người chơi</th>
              <th scope="col">Trò chơi</th>
              <th scope="col">Kỳ quay số</th>
              <th scope="col">Giá trị vé</th>
              <th scope="col">Trạng thái</th>
              <th scope="col">Lý do</th>
              <th scope="col">Hủy bởi</th>
              <th scope="col">Ngày hủy</th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map((pv, index) => <TableRow onShowDetailPress={onShowDetailPress} rIndex={index} key={`table-keys-lottmekong-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={data.totalPage || 0}
                currentPage={page || 0}
                onPageChange={(i) => {
                  setPage(i)
                }}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <TicketDetail
        show={modalShow}
        ticketId={ticketIdRef.current}
        numberRows={numberRowsRef.current}
        onDeleteTicket={deleteTicketFunc}
        onChangePeriod={changePeriodFunc}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default React.memo(_Table)
import React, { useMemo } from "react";
import { Button, Col, Modal, Row, Table } from '@themesberg/react-bootstrap';
import PowerTable from '../../../../statistic/reward/sub-screen/reward-detail/game/power'
import MegaTable from '../../../../statistic/reward/sub-screen/reward-detail/game/mega'
import KenoTable from '../../../../statistic/reward/sub-screen/reward-detail/game/keno'
import Max3dTable from '../../../../statistic/reward/sub-screen/reward-detail/game/max3d'
import Max3dProTable from '../../../../statistic/reward/sub-screen/reward-detail/game/max3dpro'

const TableRow = (props) => {
  const { numbers, rIndex, unitValue, playMethod, gameId } = props;

  return (
    <tr>
      <td>
        {rIndex + 1}
      </td>
      <td>{numbers}</td>
      <td>{parsePlayMethod(gameId, playMethod, numbers.split(' ').length)}</td>
      <td className="align-items-right">{new Intl.NumberFormat('vn-VI', {}).format(unitValue) || ""}</td>
    </tr>
  );
};


export default (props) => {

  const numberRows = useMemo(() => {
    if (props.rows.length > 0) {
      const listPrizes = props.rows

      return listPrizes
    }
    return []
  }, [props.rows])

  if (numberRows.length === 0) {
    return null;
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: 'normal', fontSize: 15 }} id="contained-modal-title-vcenter">
          {props['ticket-id'] && 'Mã vé: ' + props['ticket-id']}
          {props['game-id'] && ' ' + props['game-id']}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>
          {
            [1, 2].indexOf(props.status) >= 0 ? 'CHƯA IN' :
              [3].indexOf(props.status) >= 0 ? 'ĐÃ IN' :
                [4].indexOf(props.status) >= 0 ? 'ĐÃ TRÚNG THƯỞNG' :
                  [5].indexOf(props.status) >= 0 ? 'KHÔNG TRÚNG THƯỞNG' :
                    [6].indexOf(props.status) >= 0 ? 'Đã hủy - Hoàn tiền' :
                      [8].indexOf(props.status) >= 0 ? 'Nhân viên hủy' :
                        [9].indexOf(props.status) >= 0 ? 'Đã in' :
                      'ĐANG CHỜ XỬ LÝ'
          }
        </h5>
        <Table responsive className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">STT</th>
              <th scope="col">Dãy số</th>
              <th scope="col">Cách chơi</th>
              <th scope="col">Giá trị</th>
            </tr>
          </thead>
          <tbody>
            {numberRows && numberRows.map((pv, index) => <TableRow gameId={props['game-id']} rIndex={index} key={`rows-keys-lottmekong-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Row>
          <Col>
            Hình ảnh vé
          </Col>
        </Row>
        <Row>
          <Col>
            <img
              alt=''
              height={200}
              src={props.images[0]}
              onClick={() => {
                props.handleShowImagePreview('Mặt trước', props.images[0])
              }}
            />
          </Col>
          {props.images[1] &&
            <Col>
              <img
              alt=''
              height={200}
              src={props.images[1]}
              onClick={() => {
                props.handleShowImagePreview('Mặt sau', props.images[1])
              }}
              />
            </Col>
          }
        </Row>
        {
          props.status === 4 &&
          <>
            <Row>
              <Col>
                <b>Thông tin giải thưởng</b>
              </Col>
            </Row>
            <Row>
              {props.reward.ticket && props.reward.ticket.gameId === 'power' &&
                <PowerTable numberRows={props.reward.ticket.numberRows}
                />
              }
              {props.reward.ticket && props.reward.ticket.gameId === 'mega' &&
                <MegaTable numberRows={props.reward.ticket.numberRows}
                />
              }
              {props.reward.ticket && props.reward.ticket.gameId === 'keno' &&
                <KenoTable numberRows={props.reward.ticket.numberRows}
                />
              }
              {props.reward.ticket && props.reward.ticket.gameId === 'max3d' &&
                <Max3dTable numberRows={props.reward.ticket.numberRows}
                />
              }
              {props.reward.ticket && props.reward.ticket.gameId === 'max3dpro' &&
                <Max3dProTable numberRows={props.reward.ticket.numberRows}
                />
              }
            </Row>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Tiền thưởng:</div>
              <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(props.reward.grossValue) || ""}</strong></div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Thuế:</div>
              <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(props.reward.tax) || ""}</strong></div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Tiền thưởng sau thuế:</div>
              <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(props.reward.netValue) || ""}</strong></div>
            </div>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        {/* 1: đã thanh toán
            2: đã sãng sàng in
            3: đã hoàn thành
            4: đã trúng thưởng
            5: không trúng thưởng
            6: đã hoàn tiền 
            8: Nhân viên hủy
            9: đã in
        */}
        <Button variant="danger" onClick={props.onHide}>Close</Button>
        {[3].indexOf(props.status) >= 0 &&
          <Button variant="primary" onClick={props.handleChangeStatus}>Chụp hình lại</Button>
        }
        {[8, 9].indexOf(props.status) >= 0 &&
          <Button variant="primary" onClick={props.handleDeleteTicket}>Hủy vé hoàn tiền</Button>
        }
      </Modal.Footer>
    </Modal>
  );
}

const parseKenoPlayMethod = (method) => {
  switch (method) {
    case 'LEVEL_1':
      return 'Bậc 1'
    case 'LEVEL_2':
      return 'Bậc 2'
    case 'LEVEL_3':
      return 'Bậc 3'
    case 'LEVEL_4':
      return 'Bậc 4'
    case 'LEVEL_5':
      return 'Bậc 5'
    case 'LEVEL_6':
      return 'Bậc 6'
    case 'LEVEL_7':
      return 'Bậc 7'
    case 'LEVEL_8':
      return 'Bậc 8'
    case 'LEVEL_9':
      return 'Bậc 9'
    case 'LEVEL_10':
      return 'Bậc 10'
    case 'UPPER':
      return 'Lớn'
    case 'LOWER':
      return 'Nhỏ'
    case 'EVEN':
      return 'Chẵn'
    case 'ODD':
      return 'Lẻ'
    case 'DRAW_UPPER_LOWER':
      return 'Hòa lớn nhỏ'
    case 'DRAW_ODD_EVEN':
      return 'Hòa chẵn lẻ'
    case 'EVEN_1112':
      return 'Chẵn 11-12'
    case 'ODD_1112':
      return 'Lẻ 11-12'
    default:
      return '';
  }
}

const parseMegaPlayMethod = (method) => {
  switch (method) {
    case 'GROUP_18':
      return 'Bao 18'
    case 'GROUP_15':
      return 'Bao 15'
    case 'GROUP_14':
      return 'Bao 14'
    case 'GROUP_13':
      return 'Bao 13'
    case 'GROUP_12':
      return 'Bao 12'
    case 'GROUP_11':
      return 'Bao 11'
    case 'GROUP_10':
      return 'Bao 10'
    case 'GROUP_9':
      return 'Bao 9'
    case 'GROUP_8':
      return 'Bao 8'
    case 'GROUP_7':
      return 'Bao 7'
    case 'GROUP_5':
      return 'Bao 5'
    case 'GROUP_6':
      return 'Vé thường'
    default:
      break;
  }
}

const parseMax3dProPlayMethod = (method, numbers = 0) => {
  switch (method) {
    case 'MULTIGROUP':
      return `Bao ${numbers} bộ số`
    case 'GROUP':
      return `Bao bộ số`
    default:
      return 'BÌNH THƯỜNG';
  }
}

const parsePlayMethod = (gameId, method, numbers = 0) => {
  switch (gameId) {
    case 'keno':
      return parseKenoPlayMethod(method);
    case 'mega':
      return parseMegaPlayMethod(method);
    case 'power':
      return parseMegaPlayMethod(method);
    case 'max3d':
      return method;
    case 'max3dpro':
      return parseMax3dProPlayMethod(method, numbers);
    default:
      return '';
  }
}
import React, { useMemo } from 'react'
import { Button, Col, Row } from '@themesberg/react-bootstrap'
import { disableAuto, enableAuto } from '../../../../controller/api/worktime';
import { useCookies } from 'react-cookie';

export const DeviceItem = ({ data }) => {

  const [cookies] = useCookies(['lottmekong']);

  const numberWaitingTicket = useMemo(() => {
    const list = data.waiting
    return list
  }, [data])

  const numberTotalWaitingTicket = useMemo(() => {
    let total = 0
    const list = data.waiting

    list.forEach(element => {
      total += element._count.id
    });

    return total
  }, [data])

  const numberPrintedTicket = useMemo(() => {
    return data.printed
  }, [data])

  const numberDoneTicket = useMemo(() => {
    return data.done
  }, [data])

  const numberDeletedTicket = useMemo(() => {
    return data.deleted
  }, [data])

  const onEnableClick = async () => {
    await enableAuto(cookies.lottmekong, data.id)

    // alert('Đã bật')
  }

  const onDisableClick = async () => {
    await disableAuto(cookies.lottmekong, data.id)

    // alert('Đã tắt')
  }

  return (
    <div className="shadow-md p-2 mb-4" style={{ backgroundColor: '#fff', width: '400px' }}>
      <div style={{ fontSize: 15 }}>
        <Row>
          <Col>
            {data.employee.username} - {data.device.id}
          </Col>
          <Col className='text-right' style={{ color: numberTotalWaitingTicket === 0 ? 'red' : 'green' }}>
            {numberTotalWaitingTicket}
          </Col>
        </Row>
      </div>
      <hr className='m-0 mt-2 mb-1' />
      <div>
        {numberWaitingTicket.map((it, index) => {
          return (
            <Row key={index}>
              <Col>
                {it.gamePeriodId}
              </Col>
              <Col className='text-right' style={{ color: it._count.id === 0 ? 'red' : 'green' }}>
                {it._count.id}
              </Col>
            </Row>
          )
        })}
        {numberTotalWaitingTicket > 0 &&
          <hr className='m-0 mt-2 mb-3' />
        }
        <Row>
          <Col>
            Tên trò chơi
          </Col>
          <Col className='text-right'>
            {data.game.name}
          </Col>
        </Row>
        <Row>
          <Col>
            Số lượng đã hoàn thành
          </Col>
          <Col className='text-right'>
            {numberDoneTicket}
          </Col>
        </Row>
        <Row>
          <Col>
            Số lượng đã hủy
          </Col>
          <Col className='text-right'>
            {numberDeletedTicket}
          </Col>
        </Row>
      </div>
    </div>
  );
};
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row, Toast } from '@themesberg/react-bootstrap';
import { useCookies } from "react-cookie";
import { addInput } from "../../../../../controller/api/__classic__/input";
import { getAllChannel } from "../../../../../controller/api/__classic__/channel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { parseWeekday } from "../../../../../helper/helper";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [error, setError] = useState(false)

  const onSubmit = async () => {
    try {

      if (listInput.length > 0) {
        const _list = listInput.map((it) => ({
          channelId: it.channelId,
          resultDate: it.resultDate
        }))
  
        const resp = await addInput(cookies.lottmekong, _list)
        if (resp) {
          window.location.reload()
        } else {
          alert('Có lỗi xảy ra')
          return false
        }
      } else {
        alert('Vui lòng chọn ít nhất 1 kỳ quay số')
      }

    } catch (e) {
      alert('error')
    }
  }

  const [listInput, setListInput] = useState([])

  const [listChannel, setListChannel] = useState([])

  const [selectedChannel, setSelectedChannel] = useState(null)

  const resultDateRef = useRef(null);

  const isValidResultDayRef = useRef(false);

  const _handleChangeResultDate = useCallback((payload) => {
    const selectedWeekday = payload.format('ddd')
    
    isValidResultDayRef.current = selectedChannel.weekdays.indexOf(selectedWeekday) > -1
    
    if (isValidResultDayRef.current) {
      resultDateRef.current = payload
    } else {
      alert('Vui lòng chọn đúng ngày quay số')
    }
  }, [selectedChannel])

  const _handleAddList = useCallback(() => {
    if (selectedChannel === '' || !resultDateRef.current) {
      alert('Vui lòng chọn kênh và ngày quay số')
    } else {
      const _list = JSON.parse(JSON.stringify(listInput))
      _list.push({
        channelId: selectedChannel.id,
        channelName: selectedChannel.name,
        resultDate: resultDateRef.current
      })

      let finalList = []

      for (let i = 0; i < _list.length; i++) {
        const element = _list[i];
        let index = 0
        for (; index < finalList.length; index++) {
          const felement = finalList[index];

          if (felement.channelId === element.channelId && new Date(felement.resultDate).getTime() === new Date(element.resultDate).getTime()) {
            break;
          }
        }

        if (index === finalList.length) {
          finalList.push(element)
        }

      }

      setListInput(finalList)

    }
  }, [listInput, selectedChannel])

  const onChannelSelect = (evt) => {
    setSelectedChannel(JSON.parse(evt.target.value))
  }

  useEffect(() => {
    const getParamsFunc = async () => {
      const response = await getAllChannel(cookies.lottmekong, 0)
      if (response && response.data && response.data.length > 0) {
        const res = {
          data: response.data,
          total: response.total
        }
        setListChannel(res.data || [])
      }
    }
    getParamsFunc()
  }, [cookies.lottmekong])

  const _handleRemoveList = useCallback((index) => {

    const _list = JSON.parse(JSON.stringify(listInput)).filter((it, idx) => index !== idx)
    
    setListInput(_list)

  }, [listInput])

  const listInputView = useMemo(() => {
    return (
      listInput.map((it, index) => {
        return (
          <Row key={it.channelId + it.resultDate} className="mb-3">
            <Col md={2}>
              <Button bsPrefix="text" variant="danger" className="m-3" onClick={() => _handleRemoveList(index)}>
                <FontAwesomeIcon icon={faMinus} />
              </Button>
            </Col>
            <Col md={5}>{it.channelName}</Col>
            <Col md={5}>{moment(it.resultDate || '').format('DD/MM/YYYY').toLocaleString()}</Col>

          </Row>
        )
      })
    )
  }, [listInput, _handleRemoveList])

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          NHẬP VÉ
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Toast className="d-inline-block m-1" onClose={() => setError(false)} show={error} delay={3000} autohide bg='danger' key={3} >
          <Toast.Header>
            <strong className="me-auto">Lỗi</strong>
          </Toast.Header>
          <Toast.Body>Không thêm được</Toast.Body>
        </Toast>
        <Form>
          <Row>
            <Col md={5} className="mb-2">
              <Form.Group id="code">
                <Form.Select id="channel-id" defaultValue={''} onChange={onChannelSelect}>
                  <option key={'no-select'} value={''}>{'Chọn kênh'}</option>
                  {listChannel.map(item => <option key={item.id} value={JSON.stringify(item)}>{item.name}</option>)}
                </Form.Select>
              </Form.Group>
              {selectedChannel ? selectedChannel.weekdays.map(it => parseWeekday(it)).join(' - ') : ''}
            </Col>
            <Col md={5} className="mb-2">
              <Datetime
                timeFormat={false}
                onChange={_handleChangeResultDate}
                renderInput={(props, openCalendar) => (
                  <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                    <Form.Control
                      required
                      type="text"
                      value={resultDateRef.current ? moment(resultDateRef.current).format("DD/MM/YYYY") : ""}
                      placeholder="Ngày quay số"
                      onFocus={openCalendar}
                      onChange={() => { }} />
                  </InputGroup>
                )}
              />
            </Col>
            <Col md={2}>
              <Button variant="outline-primary" onClick={_handleAddList}>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Col>
          </Row>
          <h5>Danh sách kỳ quay sẽ thêm</h5>

          {listInputView}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
        <Button variant="success" onClick={onSubmit}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

import React, { useCallback, useEffect, useReducer } from "react";

import Table from "./partials/Table";
import { useCookies } from "react-cookie";
import { calculatePage, parseQuery } from "../../../helper/helper";
import { useLocation } from "react-router-dom";
import Summary from "./partials/Summary";
import { getChargeStatisticList } from "../../../controller/api/statistic/charge";

export const Context = React.createContext(null);

const initialData = {
  filter: {
    from: new Date(),
    to: new Date(),
    phone: '',
    bankId: '',
    page: 0
  },
  data: {
    totalValue: '0',
    count: 0,
    list: [],
    total: 0,
    totalPage: 0
  },
  filterPressCount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMIT_FILTER':
      return {
        ...state,
        filter: {
          ...action.payload,
          page: 0
        },
        filterPressCount: state.filterPressCount + 1
      }
    case 'UPDATE_DATA':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    case 'UPDATE_SUMMARY':
      return {
        ...state,
        data: {
          ...state.data,
          count: action.count,
          totalValue: action.totalValue
        }
      }
    case 'UPDATE_PAGE':
      return {
        ...state,
        filter: {
          ...state.filter,
          page: action.payload
        }
      }
    default:
      return {
        ...state
      }
  }
}

export default () => {

  var [state, dispatch] = useReducer(reducer, initialData);

  const location = useLocation();

  const [cookies] = useCookies(['lottmekong'])

  const getAllFunc = useCallback(async () => {
    if (state.filter.bankId) {
      let phone = state.filter.phone

      if (phone.indexOf('MB') < 0) {
        phone = phone.replace('0', '+84')
      }
      const response = await getChargeStatisticList(cookies.lottmekong, {
        from: state.filter.from,
        to: state.filter.to,
        phone: phone,
        bankId: parseInt(state.filter.bankId),
        methodId: isNaN(state.filter.bankId) ? state.filter.bankId : null
      }, state.filter.page)

      if (response) {
        dispatch({
          type: 'UPDATE_DATA',
          payload: {
            list: response.data,
            total: response.total,
            totalPage: calculatePage(response.total)
          }
        })
      }
    }
  }, [cookies.lottmekong, state.filter.from, state.filter.to, state.filter.page, state.filter.bankId, state.filter.phone])

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc, state.filterPressCount, state.filter.page])

  useEffect(() => {

    const queryObj = parseQuery(location.search);

    if (queryObj) {
      dispatch({
        type: 'UPDATE_SUMMARY',
        count: queryObj.count,
        totalValue: queryObj.totalValue
      })

      dispatch({
        type: 'SUBMIT_FILTER',
        payload: {
          from: queryObj.from,
          to: queryObj.to,
          phone: queryObj.phone,
          bankId: queryObj.bankId
        }
      })
    }

  }, [location.search]) // not dependency of state => avoid loop forever

  useEffect(() => {
    document.title = "Chi tiết nạp tiền"
  }, []);

  return (
    <Context.Provider value={{
      state,
      dispatch
    }}>
      <Summary />
      <br />
      <Table />
    </Context.Provider>
  );
};

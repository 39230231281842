import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row, Card, Table, Button } from '@themesberg/react-bootstrap'
import { getPowerPeriods } from '../../../../controller/api/gamePeriod'
import { useCookies } from 'react-cookie'
import { TableRow } from '../component/TableRow'
import { calculatePage } from '../../../../helper/helper'
import Pagination from '../../../../components/Pagination'
import AddPeriodPopup from '../sub-screen/add-period-popup/AddPeriodPopup'

const _Table = () => {

  const [cookies] = useCookies(['lottmekong'])

  const [data, setData] = useState({})

  const [page, setPage] = useState(0)

  const oldPage = useRef(page)

  const getAllFunc = useCallback(async () => {
    const response = await getPowerPeriods(cookies.lottmekong, {
      
    }, oldPage.current)

    if (response) {
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }
      setData(res || {})
    }
  }, [cookies.lottmekong])

  const [addPeriodPopupShow, setAddPeriodPopupShow] = useState(false)

  useEffect(() => {
    oldPage.current = page
    getAllFunc()
  }, [page, getAllFunc])

  const onShowInputResultPopupPress = () => {
    setAddPeriodPopupShow(true)
  }

  return (
    <>
      <Button className='mb-3' style={{ marginRight: 20 }} variant="success" type="submit" onClick={onShowInputResultPopupPress}>Thêm kỳ</Button>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th scope="col">Kỳ quay số</th>
              <th scope="col">Ngày quay số</th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map((pv, i) => <TableRow index={i} key={`period-status-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={data.totalPage || 0}
                currentPage={oldPage.current || 0}
                onPageChange={(i) => {
                  setPage(i)
                }}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <AddPeriodPopup
        show={addPeriodPopupShow}
        onHide={() => setAddPeriodPopupShow(false)}
      />
    </>
  );
};

export default React.memo(_Table)
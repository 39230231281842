import React, { useCallback, useContext, useEffect, useState, useRef } from 'react'
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap'
import { getListClassicSalesTicket } from '../../../../controller/api/__classic__/ticketStorage'
import { useCookies } from 'react-cookie'
import { TableRow } from '../component/TableRow'
import { calculatePage } from '../../../../helper/helper'
import Pagination from '../../../../components/Pagination'
import { Context } from '../SalesTicketStorage'

const _Table = () => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [data, setData] = useState({})

  const [page, setPage] = useState(0)

  let storageIdRef = useRef('')

  let numberRef = useRef('')

  const [updateTicketPopupShow, setUpdateTicketPopupShow] = useState(false)

  const handleUpdateTicket = (id, numbers) => {
    storageIdRef.current = id
    numberRef.current = numbers
    setUpdateTicketPopupShow(true)
  }

  const getAllFunc = useCallback(async () => {

    const response = await getListClassicSalesTicket(cookies.lottmekong, {
      from: localStorage.getItem('sts_from_date') ? localStorage.getItem('sts_from_date') : state.from,
      to: localStorage.getItem('sts_to_date') ? localStorage.getItem('sts_to_date') : state.to,
     
      channel: state.channel,
      isSingle: state.isSingle,
    }, page)

    if (response) {
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }
      setData(res || {})
    }
  }, [cookies.lottmekong, state.from, state.to, state.phone, state.channel, state.isSingle, page])

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th scope="col">STT</th>
              <th scope="col">Mã vé</th>
              <th scope="col">Kênh</th>
              <th scope="col">Ngày quay số</th>
              <th scope="col">Dãy số</th>
              <th scope="col">Số lượng</th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map((pv, index) => <TableRow handleUpdateTicket={handleUpdateTicket} rIndex={index} key={`table-keys-lottmekong-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={data.totalPage || 0}
                currentPage={page || 0}
                onPageChange={(i) => {
                  setPage(i)
                }}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default React.memo(_Table)
import React, { useEffect, useState, useCallback } from 'react'
import { Col, Row, Card, Table, Form } from '@themesberg/react-bootstrap'
import { getAll, update } from '../../../../controller/api/paymentMethod';
import { useCookies } from 'react-cookie';
import { calculatePage } from '../../../../helper/helper';
import Pagination from '../../../../components/Pagination';

const TableRow = (props) => {
  const { name, status, id, token } = props

  return (
    <tr>
      <td>
        {name}
      </td>
      <td style={{ width: 250 }}>
        <Form.Select defaultValue={status} onChange={(evt) => { update(token, id, parseInt(evt.target.value)) }} >
          <option value={0}>Không hoạt động</option>
          <option value={1}>Hoạt động</option>
        </Form.Select>
      </td>
    </tr>
  );
};

export const PaymentMethodTable = () => {

  const [data, setData] = useState({})

  const [page, setPage] = useState(0)

  const [loaded, setLoaded] = useState(false)

  const [cookies] = useCookies(['lottmekong'])

  const getAllFunc = useCallback(async () => {
    const response = await getAll(cookies.lottmekong, page)

    if (response) {
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }

      setData(res || {})
      setLoaded(true)
    }
  }, [cookies.lottmekong, page])

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Hình thức thanh toán ({data.total})</h5>
            </Col>
          </Row>
        </Card.Header>
        <Table responsive className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">Tên</th>
              <th scope="col">Trạng thái</th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map(pv => <TableRow token={cookies.lottmekong} key={`bank-status-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={data.totalPage}
                currentPage={page}
                onPageChange={(i) => {
                  setLoaded(false)
                  setPage(i)
                }}
              />
            </Col>
            <Col>
              {loaded ? '' : 'Đang tải dữ liệu'}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};
import React, { useContext } from 'react'
import { Card, Table } from '@themesberg/react-bootstrap'
import { TableRow } from '../component/TableRow'
import { Context } from '../EwalletAndPaymentGatewaySummary'

const _Table = () => {

  const { state } = useContext(Context);

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th scope="col">Thao tác</th>
              <th scope="col">Hình thức</th>
              <th scope="col">Số GD</th>
              <th scope="col">Giá trị</th>
              <th scope="col">Phí</th>
              <th scope="col">Phí cố định</th>
              <th scope="col">Tổng tiền</th>
            </tr>
          </thead>
          <tbody>
            {state.data.list && state.data.list.map((pv, index) => <TableRow from={state.filter.from} to={state.filter.to} rIndex={index} key={`table-keys-lottmekong-${pv.method.id}-${pv.subMethod}`} {...pv} />)}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default React.memo(_Table)
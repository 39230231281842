import axios from 'axios'
import { host } from '../../helper/config'
import { ITEM_PER_PAGE } from '../../helper/helper'

const PLUGINS_NAME = 'admin-tickets'

export const getList = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-list`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const getIssueList = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-issue-list`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const changeToWaiting = async (token, ticketId, gameId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/update-ticket`, {
        ticketId: ticketId,
        gameId: gameId,
        status: 9
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const changeToCurrentPeriod = async (token, ticketId, gameId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/update-ticket`, {
        ticketId: ticketId,
        gameId: gameId,
        status: 1
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const deleteTicket = async (token, ticketId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/delete-ticket`, {
        ticketId: ticketId
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

import React, { useContext, useState } from 'react'
import { Col, Row, Card, Table, Button } from '@themesberg/react-bootstrap'
import { TableRow } from '../component/TableRow'
import Pagination from '../../../../components/Pagination'
import { Context } from '../FirstAgent'
import AddAgentPopup from '../sub-screen/add/Add'

const _Table = () => {

  const { state, dispatch, deleteFunc } = useContext(Context);

  const [addPopupShow, setAddPopupShow] = useState(false)

  const onShowAddPopupPress = () => {
    setAddPopupShow(true)
  }

  return (
    <>
    <Button className='mb-3' style={{ marginRight: 20 }} variant="success" type="submit" onClick={onShowAddPopupPress}>Thêm đại lý</Button>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th scope="col">Thao tác</th>
              <th scope="col">Mã đại lý</th>
              <th scope="col">Thông tin</th>
              <th scope="col">Số thành viên</th>
            </tr>
          </thead>
          <tbody>
            {state.data.list && state.data.list.map((pv, index) => <TableRow onDelete={deleteFunc} rIndex={index} key={`table-keys-lottmekong-${index}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={state.data.totalPage || 0}
                currentPage={state.filter.page || 0}
                onPageChange={(i) => {
                  dispatch({
                    type: 'UPDATE_PAGE',
                    payload: i
                  })
                }}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <AddAgentPopup
        show={addPopupShow}
        onHide={() => setAddPopupShow(false)}
      />
    </>
  );
};

export default React.memo(_Table)
import React, { useContext } from 'react'
import { Tab, Tabs } from '@themesberg/react-bootstrap'
import InternalTable from './InternalTable'
import BankTable from './BankTable'
import { Context } from '../WithdrawSummary';

const _Tabs = () => {

  const { state, dispatch } = useContext(Context);

  return (
    <>
      <Tabs
        activeKey={state.method}
        onSelect={(k) => {
          dispatch({
            type: 'CHANGED_METHOD',
            payload: k
          })
        }}
        className="nav nav-pills nav-fill flex-column flex-md-row"
      >
        <Tab eventKey="BANKING" title="Ngân hàng">
          <BankTable />
        </Tab>
        <Tab eventKey="INTERNAL" title="Ví ứng dụng">
          <InternalTable />
        </Tab>
      </Tabs>
    </>
  );
};

export default React.memo(_Tabs)
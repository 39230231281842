export const Routes = {

    // authen
    Signin: { path: "/login-in" },
    Signup: { path: "/sign-up" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password" },
    ServerError: { path: "/server-error" },

    // trang chủ
    Overview: { path: "/" },
    VietlottOverview: { path: "/vietlott/" },
    ClassicOverview: { path: "/classic" },

    Maintained: { path: "/maintained" },

    // nạp tiền
    AddCharge: { path: "/funds/add-charge" },
    ChargeHistory: { path: "/funds/charge-history" },
    IssueCharge: { path: "/funds/issue-charge" },
    Refund: { path: "/funds/refund-user" },
    RefundHistory: { path: "/funds/refund-history" },

    // quản lý
    Cart: { path: "/management/cart" },
    Withdraw: { path: "/management/withdraw" },
    WithdrawRequest: { path: "/management/withdraw-request" },
    Ticket: { path: "/management/ticket" },
    IssueTicket: { path: "/management/issue-ticket" },
    
    // kết quả
    KenoResult: { path: "/result/keno-result" },
    PowerResult: { path: "/result/power-result" },
    MegaResult: { path: "/result/mega-result" },
    Max3dResult: { path: "/result/max3d-result" },
    Max3dProResult: { path: "/result/max3d-pro-result" },
    
    // thống kê
    CashBack: { path: "/statistics/cashback" },
    DeletedTicket: { path: "/statistics/revenue-by-deleted-ticket" },
    RevenueByPrintedTicket: { path: "/statistics/revenue-by-printed-ticket" },
    RevenueByDoneTicket: { path: "/statistics/revenue-by-done-ticket" },
    RevenueByWaitingTicket: { path: "/statistics/revenue-by-waiting-ticket" },
    ChargeSummary: { path: "/statistics/charge-summary" },
    ChargeList: { path: "/statistics/charge-list" },
    TransactionSummary: { path: "/statistics/transaction-summary" },
    TransactionList: { path: "/statistics/transaction-list" },
    Reward: { path: "/reward/reward" },
    BigReward: { path: "/reward/big-reward" },
    WithdrawSummary: { path: "/statistics/withdraw-summary" },
    NewUserSummary: { path: "/statistics/new-user-summary" },
    BankSummary: { path: "/statistics/by-bank-summary" },
    EwalletAndPaymentGatewaySummary: { path: "/statistics/by-ewallet-and-payment-gateway-summary" },
    EwalletAndPaymentGatewayDetail: { path: "/statistics/by-ewallet-and-payment-gateway-detail" },
    
    // kỳ quay
    DayOff: { path: "/period/day-off" },
    KenoPeriod: { path: "/period/keno-period" },
    PowerPeriod: { path: "/period/power-period" },
    MegaPeriod: { path: "/period/mega-period" },
    Max3dPeriod: { path: "/period/max3d-period" },
    Max3dProPeriod: { path: "/period/max3d-pro-period" },

    // thông tin chung
    Devices: { path: "/commons/devices" },
    Games: { path: "/commons/games" },
    Banner: { path: "/commons/banner" },
    PaymentMethod: { path: "/commons/payment-method" },
    Account: { path: "/commons/account" },
    Bank: { path: "/commons/bank" },
    Notification: { path: "/commons/notification" },
    Privacy: { path: "/commons/privacy" },
    GameGuide: { path: "/commons/game-guide" },
    Contact: { path: "/commons/contact" },
    Invite: { path: "/commons/invite" },
    
    // tài khoản
    FirstAgent: { path: "/account/first-agent" },
    SecondAgent: { path: "/account/second-agent" },
    Collaborators: { path: "/account/collaborator" },
    User: { path: "/account/user" },
    Employee: { path: "/account/employee" },

    // hệ thống
    SystemNotification: { path: "/systems/system-notification" },

    // CLASSIC
    ClassicListReward: { path: "/classic/list-reward" },
    ClassicChannel: { path: "/classic/channel" },
    ClassicInput: { path: "/classic/input" },
    ClassicOutput: { path: "/classic/output" },
    ClassicResult: { path: "/classic/result" },
    ClassicReward: { path: "/classic/reward" },
    ClassicCart: { path: "/classic/cart" },
    ClassicTicket: { path: "/classic/ticket" },
    ClassicTicketStorage: { path: "/classic/ticket-storage" },
    ClassicTicketStorageOutput: { path: "/classic/ticket-storage-output" },
    ClassicTicketSales: { path: "/classic/ticket-sales" },
    ClassicStatistic: { path: "/classic/statistic" },
    ClassicStatisticByTicket: { path: "/classic/statistic-by-ticket" },
    ClassicStatisticByUnmarketableTicket: { path: "/classic/statistic-by-unmarketable-ticket" },
    ClassicPeriod: { path: "/classic/period" },

    NotFound: { path: '/not-found' }
};
import axios from 'axios'
import { host } from '../../helper/config'

const PLUGINS_NAME = 'admin-commons'

export const getPrivacy = async (token) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-privacy`, {}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const getContact = async (token) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-contact`, {}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const getInvite = async (token) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-invite`, {}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const updatePrivacy = async (token, content) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/update-privacy`,
        {
            content: content,
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data
    }
    return null
}

export const updateContact = async (token, content) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/update-contact`,
        {
            content: content,
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data
    }
    return null
}

export const updateInvite = async (token, content) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/update-invite`,
        {
            content: content,
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data
    }
    return null
}
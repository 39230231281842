import React from "react";
import { Table } from '@themesberg/react-bootstrap';

const TableRow = (props) => {
  const { numbers, rIndex, playMethod, rewardAmount, rewards, matchedNumbers } = props;

  if (rewards && rewards.length > 0) {
    return (
      <tr>
        <td>
          {['A', 'B', 'C', 'D', 'E', 'F'][rIndex]}
        </td>
        <td>
          {numbers.split(' ').map(it => {
            const isMatched = matchedNumbers.indexOf(it) >= 0
            return <span className={`text-${isMatched ? 'danger' : 'primary'}`}> &nbsp;{it} &nbsp;</span>
          })}
        </td>
        <td>{parsePlayMethod(playMethod)}</td>
        <td>{rewards[0].name}</td>
        <td className="align-items-right">{new Intl.NumberFormat('vn-VI', {}).format(rewardAmount) || ""}</td>
      </tr>
    );
  }
  return null;
};

export default ({ numberRows }) => {
  return (
    <Table responsive className="align-items-center table-flush">
      <thead className="thead-light">
        <tr>
          <th scope="col">STT</th>
          <th scope="col">Dãy số</th>
          <th scope="col">Cách chơi</th>
          <th scope="col">Tên giải thưởng</th>
          <th scope="col">Giá trị</th>
        </tr>
      </thead>
      <tbody>
        {numberRows && numberRows.map((pv, index) => <TableRow rIndex={index} key={`rows-keys-lottmekong-${pv.id}`} {...pv} />)}
      </tbody>
    </Table>
  );
}

const parsePlayMethod = (method) => {
  switch (method) {
    case 'LEVEL_1':
      return 'Bậc 1'
    case 'LEVEL_2':
      return 'Bậc 2'
    case 'LEVEL_3':
      return 'Bậc 3'
    case 'LEVEL_4':
      return 'Bậc 4'
    case 'LEVEL_5':
      return 'Bậc 5'
    case 'LEVEL_6':
      return 'Bậc 6'
    case 'LEVEL_7':
      return 'Bậc 7'
    case 'LEVEL_8':
      return 'Bậc 8'
    case 'LEVEL_9':
      return 'Bậc 9'
    case 'LEVEL_10':
      return 'Bậc 10'
    case 'UPPER':
      return 'Lớn'
    case 'LOWER':
      return 'Nhỏ'
    case 'EVEN':
      return 'Chẵn'
    case 'ODD':
      return 'Lẻ'
    case 'DRAW_UPPER_LOWER':
      return 'Hòa lớn nhỏ'
    case 'DRAW_ODD_EVEN':
      return 'Hòa chẵn lẻ'
    case 'EVEN_1112':
      return 'Chẵn 11-12'
    case 'ODD_1112':
      return 'Lẻ 11-12'
    default:
      return '';
  }
}


import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row, Table } from '@themesberg/react-bootstrap';
import { DeviceItem } from './partials/DeviceItem'
import { useCookies } from "react-cookie";
import { getAllWorkTime } from "../../../controller/api/worktime";
import topbar from "topbar";
import { getCurrentPeriodTicket } from "../../../controller/api/gamePeriod";
import { GameItem } from "./partials/GameItem";

export default () => {
  const [cookies] = useCookies(['lottmekong']);

  const [list, setList] = useState([])

  const [listPeriod, setListPeriod] = useState([])

  const [loaded, setLoaded] = useState(true);

  const getAllFunc = useCallback(async () => {
    setLoaded(false)
    let _list = await getAllWorkTime(cookies.lottmekong)

    const [keno, mega, power, max3d, max3dpro] = await Promise.all([
      await getCurrentPeriodTicket(cookies.lottmekong, 'keno'),
      await getCurrentPeriodTicket(cookies.lottmekong, 'mega'),
      await getCurrentPeriodTicket(cookies.lottmekong, 'power'),
      await getCurrentPeriodTicket(cookies.lottmekong, 'max3d'),
      await getCurrentPeriodTicket(cookies.lottmekong, 'max3dpro'),
    ])

    setLoaded(true)

    setListPeriod([
      {
        ...keno,
        game: 'keno'
      },
      {
        ...mega,
        game: 'mega'
      },
      {
        ...power,
        game: 'power'
      },
      {
        ...max3d,
        game: 'max3d'
      },
      {
        ...max3dpro,
        game: 'max3dpro'
      },
    ])

    setList(_list)

  }, [cookies.lottmekong])

  useEffect(() => {
    if (loaded) {
      topbar.hide();
    } else {
      topbar.show();
    }
  }, [loaded])

  useEffect(() => {
    document.title = "Trang chủ"
  }, []);

  useEffect(() => {
    topbar.config({
      autoRun: true,
      barThickness: 3,
      barColors: {
        '0': 'rgba(26,  188, 156, .9)',
        '.25': 'rgba(52,  152, 219, .9)',
        '.50': 'rgba(241, 196, 15,  .9)',
        '.75': 'rgba(230, 126, 34,  .9)',
        '1.0': 'rgba(211, 84,  0,   .9)'
      },
      shadowBlur: 10,
      shadowColor: 'rgba(0,   0,   0,   .6)'
    })
    getAllFunc();
    const _intervalRef = setInterval(() => {
      getAllFunc();
    }, 10000);
    return () => {
      clearInterval(_intervalRef)
    }
  }, [getAllFunc])

  const renderList = useMemo(() => {

    const count = list.length

    let _index = 0

    let parent = []

    let childrens = []

    while (_index < count) {

      if (childrens.length === 4) {
        parent.push(childrens)
        childrens = []
      }

      childrens.push(list[_index])

      _index++

    }

    parent.push(childrens)

    return parent

  }, [list])

  const renderListPeriod = useMemo(() => {

    const count = listPeriod.length

    let _index = 0

    let parent = []

    let childrens = []

    while (_index < count) {

      if (childrens.length === 4) {
        parent.push(childrens)
        childrens = []
      }

      childrens.push(listPeriod[_index])

      _index++

    }

    parent.push(childrens)

    return parent

  }, [listPeriod])

  return (
    <>
      <Table responsive className="align-items-center table-bordered table-hover">
        <thead className='text-center'>
          <tr>
            <th scope="col">Thao tác</th>
            <th scope="col">Sản phẩm</th>
            <th scope="col">Kỳ</th>
            <th scope="col">USB</th>
            <th scope="col">Mới</th>
            <th scope="col">Chờ</th>
            <th scope="col">Hủy</th>
            <th scope="col">In</th>
            <th scope="col">Hoàn thành</th>
            <th scope="col"><b>Tổng cộng</b></th>
          </tr>
        </thead>
        <tbody>
          {listPeriod.map((pv, i) => {
            return (
              <GameItem key={`table-keys-lottmekong-${pv.game}`} data={pv} />
            )
          })}
        </tbody>
      </Table>

      <Col>
        {renderList.map((parent, i) => {
          return (
            <Row key={i}>
              {parent.map((children, j) => {
                return (
                  <Col key={j} md={4}>
                    <DeviceItem data={children} />
                  </Col>
                )
              })}
            </Row>
          )
        })}
      </Col>
    </>
  );
};

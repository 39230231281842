import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap'
import { Context } from '../ByWaitingTicket'
import { getWaitingTicketStatisticSummary } from '../../../../controller/api/statistic/waitingTicket';
import { useCookies } from 'react-cookie';

const _Summary = () => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [data, setData] = useState({
    count: 0,
    total: '0',
  })

  const getSummaryFunc = useCallback(async () => {

    let phone = state.filter.phone

    if (phone.indexOf('MB') < 0) {
      phone = phone.replace('0', '+84')
    }

    const response = await getWaitingTicketStatisticSummary(cookies.lottmekong, {
      phone: phone,
      gameId: state.filter.gameId
    })

    if (response) {
      const res = {
        count: response.count,
        total: response.total
      }
      setData(res || {})
    }
  }, [cookies.lottmekong, state.filter.phone, state.filter.gameId])

  useEffect(() => {
    getSummaryFunc()
  }, [getSummaryFunc, state.filterPressCount])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Body>
          <Col>
            <Row>
              <Col>
                <Row md={12} className='text-center align-self-center'>
                  <Form.Label>Tổng số vé</Form.Label>
                </Row>
                <Row md={12} className='text-center align-self-center'>
                  <Form.Label>{data.count}</Form.Label>
                </Row>
              </Col>
              <Col>
                <Row md={12} className='text-center align-self-center'>
                  <Form.Label>Tổng tiền</Form.Label>
                </Row>
                <Row md={12} className='text-center align-self-center'>
                  <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(data.total) || ""}</Form.Label>
                </Row>
              </Col>
            </Row>
          </Col>
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_Summary)
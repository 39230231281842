import axios from 'axios'
import { host } from '../../helper/config'
import { ITEM_PER_PAGE } from '../../helper/helper'

const PLUGINS_NAME = 'admin-charge'

// GET LIST CHARGE
export const getListCharge = async (token, query, page) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/get-list-charge`,
        {
            ...query,
            page: page,
            itemPerPage: ITEM_PER_PAGE
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const getSummaryCharge = async (token, query) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-summary-charge`, {
        ...query
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const getCsvCharge = async (token, query, page, totalPage) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-csv-charge`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE,
        totalPage: totalPage
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })
    
    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

// ADD CHARGE
export const addCharge = async (token, chargeValue, chargeMethod, accountId, phone, referenceNumber) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/add-charge`,
        {
            chargeValue: chargeValue,
            chargeMethod: chargeMethod,
            accountId: accountId,
            phone: phone,
            referenceNumber: referenceNumber.trim() === '' ? new Date().getTime().toString() : referenceNumber
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data
    }
    return null
}

export const getListIssueCharge = async (token, query, page) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/get-list-charge-issue`,
        {
            ...query,
            page: page,
            itemPerPage: ITEM_PER_PAGE
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const deleteIssueCharge = async (token, id) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/delete-charge-issue`,
        {
            id: id
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { getMessaging, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyAal6JbOq_7-vwGLjA-mnHKa0z-yirinFc",
  authDomain: "my-viet-lottery-vietlott.firebaseapp.com",
  databaseURL: "https://my-viet-lottery-vietlott-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "my-viet-lottery-vietlott",
  storageBucket: "my-viet-lottery-vietlott.appspot.com",
  messagingSenderId: "955700691963",
  appId: "1:955700691963:web:3935ef257f84223998415e",
  measurementId: "G-JS32JTC663"
};

// các tham số này là phần config lấy ra được từ phần 2. setting firebase nhé
const app = initializeApp(firebaseConfig)

export const storage = getStorage(app)

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

toast.success('🦄 Xin chào, Quản trị', {
  position: "top-right",
  autoClose: true,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);

  const title = payload.notification.title;

  document.querySelector('#' + title) && document.querySelector('#' + title).click()

  toast.warn(payload.notification.body, {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  // ...
});

export default app;

import React from "react";
import { Button, Modal, Table } from '@themesberg/react-bootstrap';
import { useCookies } from "react-cookie";
import { AddTicketTableActionRow } from "./component/AddTicketTableActionRow";
import { AddTicketTableRow } from "./component/AddTicketTableRow";
import { addTicketForInput } from "../../../../../controller/api/__classic__/input";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [data, setData] = React.useState([]);

  const _onHide = () => {
    setData([])
    props.onHide()
  }

  const onSubmit = async () => {
    const resp = await addTicketForInput(cookies.lottmekong, data)
    if (resp) {
      window.location.reload()
      _onHide()
    } else {
      alert('Có lỗi xảy ra')
      return false
    }
  }

  const onAddSingle = () => {
    const newData = JSON.parse(JSON.stringify(data))
    newData.push({
      resultId: props.id,
      isSingle: true,
      numbers: '',
      amount: 12
    })
    setData(newData)
  }

  const onAddMulti = () => {
    const newData = JSON.parse(JSON.stringify(data))
    newData.push({
      resultId: props.id,
      isSingle: false,
      numbers: '',
      amount: 110
    })
    setData(newData)
  }

  const onChangeAmount = (index, amount) => {
    const newData = JSON.parse(JSON.stringify(data))
    newData[index].amount = amount
    setData(newData)
  }

  const onChangeNumbers = (index, numbers) => {
    const newData = JSON.parse(JSON.stringify(data))
    newData[index].numbers = numbers
    setData(newData)
  }
  
  const onDelete = (index) => {
    const newData = data.filter((it, idx) => idx !== index)

    setData(newData)
  }

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      onHide={_onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.channelName} {props.resultDate}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th scope="col">STT</th>
              <th scope="col">Dãy số</th>
              <th scope="col">Cặp nguyên</th>
              <th scope="col">Số lượng</th>
              <th scope="col">Thao tác</th>
            </tr>
          </thead>
          <tbody>
            {data.map((it, idx) => <AddTicketTableRow onDelete={onDelete} onChangeNumbers={onChangeNumbers} onChangeAmount={onChangeAmount} rIndex={idx} key={`add-ticket-keys-modal-lottmekong-${idx}`} {...it} />)}
          </tbody>
          <tfoot>
            <AddTicketTableActionRow
              onAddSingle={onAddSingle}
              onAddMulti={onAddMulti}
            />
          </tfoot>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={_onHide}>Close</Button>
        <Button variant="primary" onClick={onSubmit}>Thêm</Button>
      </Modal.Footer>
    </Modal>
  );
}
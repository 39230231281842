import React, { useEffect, useReducer } from "react";

import Table from "./partials/Table";
import FilterForm from "./partials/FilterForm";

export const Context = React.createContext(null);

let next7Days = new Date()

next7Days.setDate(next7Days.getDate() + 7)

const initialFilterData = {
  from: new Date(),
  to: next7Days,
  channel: '',
  isSingle: true
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMIT_FILTER':
      return {
        ...state,
        ...action.payload
      }
    default:
      return {
        ...state
      }
  }
}

export default () => {

  var [state, dispatch] = useReducer(reducer, initialFilterData);

  useEffect(() => {
    document.title = "Vé đang bán"
  }, []);

  return (
    <Context.Provider value={{
      state,
      dispatch
    }}>

      <FilterForm />

      <br />

      <Table />
    </Context.Provider>
  );
};

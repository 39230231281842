import React, { useState, useEffect, useCallback } from 'react';
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Routes } from "../routes";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import { useCookies } from 'react-cookie';

// authen
import Signin from "./authenticate/Signin";
import Signup from "./authenticate/Signup";
import ForgotPassword from "./authenticate/ForgotPassword";
import ResetPassword from "./authenticate/ResetPassword";
import ServerError from "./authenticate/ServerError";

// trang chủ
import Overview from "./dashboard/DashboardOverview";
import VietlottOverview from "./__vietlott__/dashboard/DashboardOverview";
import ClassicOverview from "./__classic__/dashboard/DashboardOverview";

// nạp tiền
import AddCharge from './fund/charge/add-charge/AddCharge'
import ChargeHistory from './fund/charge/charge-history/ChargeHistory'
import IssueCharge from './fund/charge/issue-charge/IssueCharge'
import Refund from './fund/refund/add-refund/AddRefund'
import RefundHistory from './fund/refund/refund-history/RefundHistory'

// quản lý
import Cart from './management/cart/Cart'
import Ticket from './management/ticket/Ticket';
import IssueTicket from './management/issue-ticket/IssueTicket';
import RejectWithdrawRequest from './management/reject-withdraw-request/RejectWithdrawRequest'
import WithdrawRequest from './management/withdraw-request/WithdrawRequest'

// kết quả
import KenoResult from './result/keno/Keno'
import PowerResult from './result/power/Power'
import MegaResult from './result/mega/Mega'
import Max3dResult from './result/max3d/Max3d'
import Max3dProResult from './result/max3dpro/Max3dPro'

// thống kê
import CashBack from './statistic/cashback/CashBack'
import DeletedTicket from './statistic/by-deleted-ticket/ByDeletedTicket'
import RevenueByPrintedTicket from './statistic/by-printed-ticket/ByPrintedTicket'
import RevenueByDoneTicket from './statistic/by-done-ticket/ByDoneTicket'
import RevenueByWaitingTicket from './statistic/by-waiting-ticket/ByWaitingTicket'
import ChargeSummary from './statistic/charge-summary/ChargeSummary'
import TransactionSummary from './statistic/transaction-summary/TransactionSummary'
import Reward from './statistic/reward/Reward';
import BigReward from './statistic/big-reward/Reward';
import WithdrawSummary from './statistic/withdraw-summary/WithdrawSummary'
import NewUserSummary from './statistic/new-user/NewUser'

// kỳ quay
import DayOff from './period/day-off/DayOff'
import KenoPeriod from './period/keno/Keno'
import PowerPeriod from './period/power/Power'
import MegaPeriod from './period/mega/Mega'
import Max3dPeriod from './period/max3d/Max3d'
import Max3dProPeriod from './period/max3dpro/Max3dPro'

// thông tin chung
import Devices from './commons/device/Devices'
import Games from './commons/game/Games'
import PaymentMethod from './commons/payment-method/PaymentMethod'
import Account from './commons/account/Account'
import Bank from './commons/bank/Bank'
import Banner from './commons/banner/Banner'
import Privacy from './commons/privacy/Privacy'
import Contact from './commons/contact/Contact'
import Invite from './commons/invite/Invite'
import Notification from './commons/notification/Notification'

// tài khoản
import FirstAgent from './account/first-agent/FirstAgent';
import SecondAgent from './account/second-agent/SecondAgent';
import Collaborator from './account/collaborator/Collaborator';
import User from './account/user/User'
import Employee from './account/employee/Employee'

import SystemNotification from './systems/notification/SystemNotification';

// classic
import ClassicListReward from './__classic__/list-reward/ListReward';
import ClassicChannel from './__classic__/channel/Channel';
import ClassicInput from './__classic__/input/Input';
import ClassicOutput from './__classic__/output/Output';
import ClassicResult from './__classic__/result/Result';
import ClassicReward from './__classic__/reward/Reward';
import ClassicCart from './__classic__/cart/Cart';
import ClassicTicket from './__classic__/ticket/Ticket';
import ClassicTicketStorage from './__classic__/ticket-storage/TicketStorage';
import ClassicTicketSales from './__classic__/sales-ticket-storage/SalesTicketStorage';
import ClassicStatistic from './__classic__/statistic/Statistic';
import ClassicStatisticByUnmarketableTicket from './__classic__/statistic-by-unmarketable-ticket/Statistic';
import ClassicStatisticByTicket from './__classic__/statistic-by-ticket/Statistic';
import ClassicPeriod from './__classic__/period/Period';
import ClassicEmployee from './__classic__/employee/Employee'

import { checkToken } from '../controller/api/auth';
import { messaging } from '../firebase';
import { getToken } from 'firebase/messaging';
import { submitFbToken } from '../controller/api/messageToken';
import ClassicSidebar from '../components/ClassicSidebar';
import ChargeList from './statistic/charge-list/ChargeList';
import Maintained from './__vietlott__/maintained/Maintained';
import TransactionList from './statistic/transaction-list/TransactionList';
import BankSummary from './statistic/bank-summary/BankSummary';
import EwalletAndPaymentGatewaySummary from './statistic/ewallet-and-payment-gateway-summary/EwalletAndPaymentGatewaySummary';
import EwalletAndPaymentGatewayDetail from './statistic/ewallet-and-payment-gateway-detail/EwalletAndPaymentGatewayDetail';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>)} />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};

const RouteWithClassicSidebar = ({ component: Component, ...rest }) => {

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <ClassicSidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};

export default () => {
  const [cookies] = useCookies(['lottmekong']);

  const history = useHistory()

  const checkTokenFunc = useCallback(async () => {

    if (!cookies.lottmekong) {
      history.replace(Routes.Signin.path)
    } else {
      const response = await checkToken(cookies.lottmekong)

      if (response.status === 'success') {
        if (history.location.pathname.includes(Routes.Signin.path)) {
          const permission = response.permission

          if (permission === '11') {
            history.replace(Routes.Overview.path)
          } else if (permission === '10') {
            history.replace(Routes.VietlottOverview.path)
          } else if (permission === '01') {
            history.replace(Routes.ClassicOverview.path)
          }
        } else if (history.location.pathname === Routes.Overview.path) {
          const permission = response.permission

          if (permission === '11') {
            history.replace(Routes.Overview.path)
          } else if (permission === '10') {
            history.replace(Routes.VietlottOverview.path)
          } else if (permission === '01') {
            history.replace(Routes.ClassicOverview.path)
          }
        }
      } else {
        history.replace(Routes.Signin.path)
      }
    }
  }, [cookies.lottmekong, history])

  useEffect(() => {
    checkTokenFunc()
  }, [checkTokenFunc])

  useEffect(() => {
    getToken(messaging, {
      vapidKey: 'BLEoZzE__uWckthVeTd1PE1WnQddY4uLMAqH3D_Tj0c9DQH3YvaRTgkQAnISsZ_uC0xcRGi464CXgSMa4XIMWFI'
    }).then((currentToken) => {
      submitFbToken(cookies.lottmekong, currentToken)
    })
  }, [cookies.lottmekong])

  return (
    <Switch>

      {/* authen */}
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
      <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
      <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
      <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

      {/* trang chủ */}
      <Route exact path={Routes.Overview.path} component={Overview} />
      <RouteWithSidebar exact path={Routes.VietlottOverview.path} component={VietlottOverview} />
      <RouteWithClassicSidebar exact path={Routes.ClassicOverview.path} component={ClassicOverview} />

      <RouteWithSidebar exact path={Routes.Maintained.path} component={Maintained} />

      {/* nạp tiền */}
      <RouteWithSidebar exact path={Routes.AddCharge.path} component={AddCharge} />
      <RouteWithSidebar exact path={Routes.ChargeHistory.path} component={ChargeHistory} />
      <RouteWithSidebar exact path={Routes.IssueCharge.path} component={IssueCharge} />
      <RouteWithSidebar exact path={Routes.Refund.path} component={Refund} />
      <RouteWithSidebar exact path={Routes.RefundHistory.path} component={RefundHistory} />

      {/* quản lý */}
      <RouteWithSidebar exact path={Routes.Cart.path} component={Cart} />
      <RouteWithSidebar exact path={Routes.Ticket.path} component={Ticket} />
      <RouteWithSidebar exact path={Routes.IssueTicket.path} component={IssueTicket} />
      <RouteWithSidebar exact path={Routes.Withdraw.path} component={RejectWithdrawRequest} />
      <RouteWithSidebar exact path={Routes.WithdrawRequest.path} component={WithdrawRequest} />

      {/* kết quả */}
      <RouteWithSidebar exact path={Routes.KenoResult.path} component={KenoResult} />
      <RouteWithSidebar exact path={Routes.PowerResult.path} component={PowerResult} />
      <RouteWithSidebar exact path={Routes.MegaResult.path} component={MegaResult} />
      <RouteWithSidebar exact path={Routes.Max3dResult.path} component={Max3dResult} />
      <RouteWithSidebar exact path={Routes.Max3dProResult.path} component={Max3dProResult} />

      {/* thống kê */}
      <RouteWithSidebar exact path={Routes.CashBack.path} component={CashBack} />
      <RouteWithSidebar exact path={Routes.DeletedTicket.path} component={DeletedTicket} />
      <RouteWithSidebar exact path={Routes.RevenueByPrintedTicket.path} component={RevenueByPrintedTicket} />
      <RouteWithSidebar exact path={Routes.RevenueByDoneTicket.path} component={RevenueByDoneTicket} />
      <RouteWithSidebar exact path={Routes.RevenueByWaitingTicket.path} component={RevenueByWaitingTicket} />
      <RouteWithSidebar exact path={Routes.ChargeSummary.path} component={ChargeSummary} />
      <RouteWithSidebar exact path={Routes.ChargeList.path} component={ChargeList} />
      <RouteWithSidebar exact path={Routes.TransactionSummary.path} component={TransactionSummary} />
      <RouteWithSidebar exact path={Routes.TransactionList.path} component={TransactionList} />
      <RouteWithSidebar exact path={Routes.WithdrawSummary.path} component={WithdrawSummary} />
      <RouteWithSidebar exact path={Routes.Reward.path} component={Reward} />
      <RouteWithSidebar exact path={Routes.BigReward.path} component={BigReward} />
      <RouteWithSidebar exact path={Routes.NewUserSummary.path} component={NewUserSummary} />
      <RouteWithSidebar exact path={Routes.BankSummary.path} component={BankSummary} />
      <RouteWithSidebar exact path={Routes.EwalletAndPaymentGatewaySummary.path} component={EwalletAndPaymentGatewaySummary} />
      <RouteWithSidebar exact path={Routes.EwalletAndPaymentGatewayDetail.path} component={EwalletAndPaymentGatewayDetail} />

      {/* kỳ quay */}
      <RouteWithSidebar exact path={Routes.DayOff.path} component={DayOff} />
      <RouteWithSidebar exact path={Routes.KenoPeriod.path} component={KenoPeriod} />
      <RouteWithSidebar exact path={Routes.PowerPeriod.path} component={PowerPeriod} />
      <RouteWithSidebar exact path={Routes.MegaPeriod.path} component={MegaPeriod} />
      <RouteWithSidebar exact path={Routes.Max3dPeriod.path} component={Max3dPeriod} />
      <RouteWithSidebar exact path={Routes.Max3dProPeriod.path} component={Max3dProPeriod} />

      {/* thông tin chung */}
      <RouteWithSidebar exact path={Routes.Devices.path} component={Devices} />
      <RouteWithSidebar exact path={Routes.Notification.path} component={Notification} />
      <RouteWithSidebar exact path={Routes.Games.path} component={Games} />
      <RouteWithSidebar exact path={Routes.PaymentMethod.path} component={PaymentMethod} />
      <RouteWithSidebar exact path={Routes.Account.path} component={Account} />
      <RouteWithSidebar exact path={Routes.Bank.path} component={Bank} />
      <RouteWithSidebar exact path={Routes.Banner.path} component={Banner} />
      <RouteWithSidebar exact path={Routes.Privacy.path} component={Privacy} />
      <RouteWithSidebar exact path={Routes.Contact.path} component={Contact} />
      <RouteWithSidebar exact path={Routes.Invite.path} component={Invite} />

      {/* tài khoản */}
      <RouteWithSidebar exact path={Routes.FirstAgent.path} component={FirstAgent} />
      <RouteWithSidebar exact path={Routes.SecondAgent.path} component={SecondAgent} />
      <RouteWithSidebar exact path={Routes.Collaborators.path} component={Collaborator} />
      <RouteWithSidebar exact path={Routes.User.path} component={User} />
      <RouteWithSidebar exact path={Routes.Employee.path} component={Employee} />

      {/* Hệ thống */}
      <RouteWithSidebar exact path={Routes.SystemNotification.path} component={SystemNotification} />

      {/* classic */}
      <RouteWithClassicSidebar exact path={Routes.ClassicListReward.path} component={ClassicListReward} />
      <RouteWithClassicSidebar exact path={Routes.ClassicChannel.path} component={ClassicChannel} />
      <RouteWithClassicSidebar exact path={Routes.ClassicInput.path} component={ClassicInput} />
      <RouteWithClassicSidebar exact path={Routes.ClassicResult.path} component={ClassicResult} />
      <RouteWithClassicSidebar exact path={Routes.ClassicReward.path} component={ClassicReward} />
      <RouteWithClassicSidebar exact path={Routes.ClassicCart.path} component={ClassicCart} />
      <RouteWithClassicSidebar exact path={Routes.ClassicTicket.path} component={ClassicTicket} />
      <RouteWithClassicSidebar exact path={Routes.ClassicTicketStorage.path} component={ClassicTicketStorage} />
      <RouteWithClassicSidebar exact path={Routes.ClassicTicketSales.path} component={ClassicTicketSales} />
      <RouteWithClassicSidebar exact path={Routes.ClassicStatistic.path} component={ClassicStatistic} />
      <RouteWithClassicSidebar exact path={Routes.ClassicStatisticByTicket.path} component={ClassicStatisticByTicket} />
      <RouteWithClassicSidebar exact path={Routes.ClassicStatisticByUnmarketableTicket.path} component={ClassicStatisticByUnmarketableTicket} />
      <RouteWithClassicSidebar exact path={Routes.ClassicPeriod.path} component={ClassicPeriod} />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );
}

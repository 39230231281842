import React, { useCallback, useEffect, useRef, useState } from "react"
import { Button, Modal, ProgressBar } from '@themesberg/react-bootstrap'
import { calculateMega } from '../../../../../controller/api/result'
import { useCookies } from 'react-cookie'
import { getCountTicket } from "../../../../../controller/api/gameResult"

export default (props) => {

  const [totalTicket, setTotalTicket] = useState(0)
  const [done, setDone] = useState(false)
  const firstTime = useRef(null)
  const totalRewardTicket = useRef(0)
  const totalUnrewardTicket = useRef(0)
  const totalReward = useRef(0)

  const [cookies] = useCookies(['lottmekong'])

  const _onCalculating = useCallback(async () => {

    const response = await calculateMega(cookies.lottmekong, 10, props.period.id)

    if (response) {
      console.log(response, 'response')
      totalRewardTicket.current += response.totalRewardTicket
      totalUnrewardTicket.current += response.totalUnrewardTicket
      totalReward.current += response.totalReward
      setTotalTicket(totalTicket - response.totalTicket)
    }
  }, [cookies.lottmekong, props.period, totalTicket])

  const _countTicket = useCallback(async () => {
    if (firstTime.current === null) {
      firstTime.current = new Date()
    }

    const response = await getCountTicket(cookies.lottmekong, props.period ? props.period.id : '')

    if (response > 0) {
      setTotalTicket(response)
    } else {
      setDone(true)
    }
  }, [cookies.lottmekong, props.period])

  useEffect(() => {
    if (props.show) {
      if (totalTicket > 0) {
        _onCalculating()
      } else {
        setTimeout(() => {
          _countTicket()
        }, 2000);
      }
    }
  }, [props.show, _onCalculating, _countTicket, totalTicket])

  const _onHide = () => {
    if (done) {
      props.onHide(totalRewardTicket.current, totalUnrewardTicket.current, async () => {
        firstTime.current = null
        totalRewardTicket.current = 0
        totalUnrewardTicket.current = 0
        totalReward.current = 0
        setTotalTicket(0)
        setDone(false)
      })
    } else {
      alert('Chưa tính thưởng xong')
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={_onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Tính thưởng kỳ #{props.period && props.period.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {firstTime.current === null && 'Đang đếm vé'}
        {firstTime.current !== null && totalTicket > 0 &&
          <>
            <ProgressBar className={`progress-md`} variant={'success'} now={totalRewardTicket.current + totalUnrewardTicket.current} min={0} max={totalRewardTicket.current + totalUnrewardTicket.current + totalTicket} />
            {totalRewardTicket.current + totalUnrewardTicket.current} / <b>{totalRewardTicket.current + totalUnrewardTicket.current + totalTicket}</b><br />
            Vé trúng: <b>{totalRewardTicket.current}</b><br />
            Vé không trúng: <b> {totalUnrewardTicket.current}</b><br />
            Giải thưởng: <b> {totalReward.current}</b><br />
          </>
        }
        {firstTime.current !== null && totalTicket === 0 &&
          <>
            Không có vé mới
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={_onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

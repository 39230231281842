import React, { useEffect, useState, useCallback } from 'react'
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap'
import { getAllDevices } from '../../../../controller/api/device'
import { useCookies } from 'react-cookie';
import { calculatePage } from '../../../../helper/helper';
import Pagination from '../../../../components/Pagination';

const TableRow = (props) => {
  const { code, status } = props

  return (
    <tr>
      <td>
        {code}
      </td>
      <td className={status === 0 ? 'text-danger' : 'text-success'} style={{ width: 250 }}>
        {status === 0 ? 'Không hoạt động' : 'Hoạt động'}
      </td>
    </tr>
  );
};

export const DeviceTable = () => {

  const [data, setData] = useState({})

  const [page, setPage] = useState(0)

  const [loaded, setLoaded] = useState(false)

  const [cookies] = useCookies(['lottmekong'])

  const getAllFunc = useCallback(async () => {
    const response = await getAllDevices(cookies.lottmekong, page)

    if (response) {
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }

      setData(res || {})
      setLoaded(true)
    }
  }, [cookies.lottmekong, page])

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>USB ({data.total})</h5>
            </Col>
          </Row>
        </Card.Header>
        <Table responsive className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">Mã</th>
              <th scope="col">Trạng thái</th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map(pv => <TableRow token={cookies.lottmekong} key={`device-status-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={data.totalPage}
                currentPage={page}
                onPageChange={(i) => {
                  setLoaded(false)
                  setPage(i)
                }}
              />
            </Col>
            <Col>
              {loaded ? '' : 'Đang tải dữ liệu'}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap'
import { getListCart } from '../../../../controller/api/cart'
import { useCookies } from 'react-cookie'
import { TableRow } from '../component/TableRow'
import { calculatePage } from '../../../../helper/helper'
import Pagination from '../../../../components/Pagination'
import { Context } from '../Cart'

const _Table = () => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [data, setData] = useState({})

  const [page, setPage] = useState(0)

  const getAllFunc = useCallback(async () => {
    let phone = state.phone

    if (phone.indexOf('MB') < 0) {
      phone = phone.replace('0', '+84')
    }
    const response = await getListCart(cookies.lottmekong, {
      from: state.from,
      to: state.to,
      phone: phone,
      gameId: state.gameId
    }, page)

    if (response) {
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }
      setData(res || {})
    }
  }, [cookies.lottmekong, state.from, state.to, state.phone, state.gameId, page])

  useEffect(() => {
    if (state.phone !== '') {
      getAllFunc()
    }
  }, [getAllFunc, state.phone])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th scope="col">Thao tác</th>
              <th scope="col">Mã đơn</th>
              <th scope="col">Người chơi</th>
              <th scope="col">Trò chơi</th>
              <th scope="col">Kỳ quay số</th>
              <th scope="col">Tổng số kỳ mua</th>
              <th scope="col">Số kỳ hủy</th>
              <th scope="col">Giá trị vé</th>
              <th scope="col">Thành tiền</th>
              <th scope="col">Ngày tạo</th>
              <th scope="col">Ngày thanh toán</th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map((pv, index) => <TableRow rIndex={index} key={`table-keys-lottmekong-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={data.totalPage || 0}
                currentPage={page || 0}
                onPageChange={(i) => {
                  setPage(i)
                }}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default React.memo(_Table)
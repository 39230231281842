
import React, {  } from "react";
import { Col, Container, Nav, Row } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import { useCookies } from "react-cookie";
import { logout } from "../../controller/api/auth";
import { useHistory } from "react-router-dom";

export default () => {

  const [cookies, , removeCookie] = useCookies(['lottmekong']);

  const history = useHistory()

  const handleLogout = async () => {
    try {
      await logout(cookies.lottmekong)
    } catch (error) {

    } finally {
      removeCookie('lottmekong')
      history.replace('/')
    }
  }

  return (
    <main style={{ height: '100vh' }}>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <h1 style={{ textAlign: 'center' }}>XIN VUI LÒNG CHỌN</h1>
          <Nav fill variant="pills" className="flex-column flex-lg-row">
            <Row>
              <Col md={6}>
                <Nav.Item>
                  <Nav.Link eventKey="home" href={Routes.VietlottOverview.path} className="mb-sm-3 mb-md-0">
                    <Container style={{ display: 'flex', height: 300, width: 500, justifyContent: 'center', alignItems: 'center', fontSize: 25 }}>
                      VIETLOTT
                    </Container>
                  </Nav.Link>
                </Nav.Item>
              </Col>
              <Col lg={6}>
                <Nav.Item>
                  <Nav.Link eventKey="settings" href={Routes.ClassicOverview.path} className="mb-sm-3 mb-md-0">
                    <Container style={{ display: 'flex', height: 300, width: 500, justifyContent: 'center', alignItems: 'center', fontSize: 25 }}>
                      XSKT
                    </Container>
                  </Nav.Link>
                </Nav.Item>
              </Col>
              <Col lg={6}>
                <Nav.Item>
                  <Nav.Link eventKey="settings" onClick={handleLogout} className="mb-sm-3 mb-md-0" style={{backgroundColor: 'red'}}>
                    <Container style={{ display: 'flex', height: 100, justifyContent: 'center', alignItems: 'center', fontSize: 25, color: '#fff' }}>
                      ĐĂNG XUẤT
                    </Container>
                  </Nav.Link>
                </Nav.Item>
              </Col>
            </Row>
          </Nav>
        </Container>
      </section>
    </main >

  );
};

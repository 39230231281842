import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';
import React, { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../ChargeSummary';

export const TableRow = (props) => {
  const { count, id, logo, total, from, to, phone } = props;

  const { dispatch } = useContext(Context);

  const linkTo = `/statistics/charge-list?bankId="${id}"&from="${from}"&to="${to}"&phone="${phone}"&totalValue="${total}"&count=${count.methodId}`

  const _onExport = useCallback(() => {
    dispatch({
      type: 'EXPORT_DATA',
      bankId: id,
      exportTotal: count.methodId
    })
  }, [count.methodId, dispatch, id])

  return (
    <tr>
      <td className="text-center">
        <Button variant="success" className="m-1" onClick={_onExport}>Xuất excel</Button>
        &nbsp;
        &nbsp;
        <Button>
          <Link
            target={'_blank'}
            style={{ color: '#fff' }}
            to={linkTo}
            key={id}
          >
            {'Xem chi tiết'}
            <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
          </Link>
        </Button>
      </td>
      {!isNaN(id) ?
        <td className='text-center'>
          <img alt='' src={logo} className="img-thumbnail" width={120} height={30}></img>
        </td>
        :
        <td className='text-center'>{id}</td>
      }
      <td className='text-center'>
        {count.methodId}
      </td>
      <td className='text-center text-success'>{new Intl.NumberFormat('vn-VI', {}).format(total) || ""}</td>
    </tr>
  );
};
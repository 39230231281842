import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { user, id, gamePeriod, gameId, value, createdAt, status, WorkTime } = props;

  const statusName = status === 1 ? 'Đang chờ' : 'Sẵn sàng'
  
  const statusColor = status === 1 ? 'warning' : 'success'

  return (
    <tr>
      <td>
        {(user && user.name) || ""}<br />
        <span><strong className={`text-primary`}>{(user && user.phone) || ""}</strong></span>
      </td>
      <td className='text-center'>{id}</td>
      <td className='text-center'>
        <img alt='' src={require(`../../../../assets/img/logo-${gameId}.png`)} className="img-thumbnail" width={90} height={30}></img>
      </td>
      <td className='text-center'>{gamePeriod.name}</td>
      <td className='text-center text-success'>{new Intl.NumberFormat('vn-VI', {}).format(value) || ""}</td>
      <td className={`text-center text-${statusColor}`}><b>{statusName}</b></td>
      <td className='text-center'>{moment(createdAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()}</td>
    </tr>
  );
};
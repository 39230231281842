import React, { useCallback, useEffect, useReducer, useState } from "react";

import Table from "./partials/Table";
import FilterForm from "./partials/FilterForm";
import Summary from "./partials/Summary";
import { useCookies } from "react-cookie";
import { getTransactionStatisticSummary } from "../../../controller/api/statistic/transactionByMethod";
import ExportProcessingPopup from "./sub-screen/export-processing/ExportProcessingPopup";

export const Context = React.createContext(null);

const initialData = {
  filter: {
    from: new Date(),
    to: new Date(),
    phone: '',
    gameId: '',
  },
  data: {
    list: [],
  },
  summary: {
    total: '0',
    fee: '0',
    count: 0
  },
  filterPressCount: 0,
  exportPressCount: 0,
  exportBankId: 0,
  exportTotal: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMIT_FILTER':
      return {
        ...state,
        filter: {
          ...action.payload
        },
        filterPressCount: state.filterPressCount + 1
      }
    case 'UPDATE_DATA':
      return {
        ...state,
        data: {
          list: action.payload.list
        },
        summary: {
          total: action.payload.total,
          fee: action.payload.fee,
          count: action.payload.count
        }
      }
    case 'EXPORT_DATA':
      return {
        ...state,
        exportPressCount: state.exportPressCount + 1,
        exportBankId: action.bankId,
        exportTotal: action.exportTotal
      }
    default:
      return {
        ...state
      }
  }
}

export default () => {

  var [state, dispatch] = useReducer(reducer, initialData);

  const [showProcessingModal, setShowProcessingModal] = useState(false)

  const [cookies] = useCookies(['lottmekong'])

  const getAllFunc = useCallback(async () => {
    let phone = state.filter.phone

    if (phone.indexOf('MB') < 0) {
      phone = phone.replace('0', '+84')
    }
    const response = await getTransactionStatisticSummary(cookies.lottmekong, {
      from: state.filter.from,
      to: state.filter.to,
      phone: phone,
      gameId: state.filter.gameId,
    })

    if (response) {
      dispatch({
        type: 'UPDATE_DATA',
        payload: {
          list: response.data,
          count: response.count,
          total: response.total,
          fee: response.fee,
        }
      })
    }
  }, [cookies.lottmekong, state.filter.from, state.filter.to, state.filter.phone,  state.filter.gameId])

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc, state.filterPressCount])

  useEffect(() => {
    document.title = "Thanh toán"
  }, []);

  useEffect(() => {
    if (state.exportPressCount > 0) {
      setShowProcessingModal(true)
    }
  }, [state.exportPressCount])

  return (
    <Context.Provider value={{
      state,
      dispatch
    }}>

      <FilterForm />

      <br />

      <Summary />

      <br />

      <Table />
      <ExportProcessingPopup
        total={state.exportTotal}
        methodId={state.exportBankId}
        show={showProcessingModal}
        onHide={() => setShowProcessingModal(false)}
      />
    </Context.Provider>
  );
};

import React from 'react';

export const TableRow = (props) => {
  const { count, logo, value } = props;

  return (
    <tr>
      {/* <td className="text-center">
        <Button variant="success" className="m-1" onClick={_onExport}>Xuất excel</Button>
        &nbsp;
        &nbsp;
        {/* <Button>
          <Link
            target={'_blank'}
            style={{ color: '#fff' }}
            to={linkTo}
            key={id}
          >
            {'Xem chi tiết'}
            <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
          </Link>
        </Button>
  </td> */}
      <td className='text-center'>
        <img alt='' src={logo} className="img-thumbnail" width={120} height={30}></img>
      </td>
      <td className='text-center'>
        {count.methodId}
      </td>
      <td className='text-center text-success'>{new Intl.NumberFormat('vn-VI', {}).format(value) || ""}</td>
    </tr>
  );
};
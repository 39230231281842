
import React, { useEffect } from "react";
import { Col, Row } from '@themesberg/react-bootstrap';

import { AddChargeForm } from "./partials/AddChargeForm";

export default () => {

  useEffect(() => {
    document.title = "Nạp tiền"
  }, []);

  return (
    <>
      <Row className="mt-4">
        <Col xs={12}>
          <AddChargeForm />
        </Col>
      </Row>
      
    </>
  );
};

import React from 'react'

export const TableRow = (props) => {
  const { name, resultDate, resultTime } = props;

  return (
    <tr>
      <td className="text-center">{'#' + name || ""}</td>
      <td className="text-center">{resultDate}</td>
      <td className="text-center">{resultTime}</td>
    </tr>
  );
};
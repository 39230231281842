import React, { useState } from "react";
import { Col, Row, Form, Alert } from '@themesberg/react-bootstrap';
import { useCookies } from "react-cookie";
import { getUserByPhone } from "../../../../../../controller/api/user";

export const AddNotificationForm = (props) => {

  const [isNotifyToAll, setIsNotifyToAll] = useState(1)

  const [detectedUser, setDetectedUser] = useState(null)

  const [cookies] = useCookies(['lottmekong'])

  const onMessageChange = (evt) => {
    props.setMessage(evt.target.value)
  }

  const onTitleChange = (evt) => {
    props.setTitle(evt.target.value)
  }

  const onDestSelect = (evt) => {
    props.setIsGetAll(evt.target.value)
    props.setDetectedUser(null)
    setIsNotifyToAll(evt.target.value)
  }

  const getUser = async (evt) => {
    try {
      if (evt.target.value.length === 10) {
        const phone = evt.target.value.replace('0', '+84')
  
        const response = await getUserByPhone(cookies.lottmekong, phone)
  
        if (response) {
          props.setDetectedUser(response)
          setDetectedUser(response)
        } else {
          alert('Không tìm thấy')
        }
      } else if (evt.target.value.length > 10) {
        return false
      }
    } catch (error) {
      alert('Không tìm thấy')
    }
  }

  return (
    <Form>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Tiêu đề</Form.Label>
            <Form.Control required type="text" placeholder="Nhập tiêu đề" onChange={onTitleChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Nội dung</Form.Label>
            <Form.Control required type="text" placeholder="Nhập nội dung" onChange={onMessageChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="mb-3">
          <Form.Group className="mb-2">
            <Form.Label>Chọn đối tượng</Form.Label>
            <Form.Select id="dest-id" defaultValue={1} onChange={onDestSelect}>
              <option value={1}>Tất cả</option>
              <option value={0}>Khách hàng</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ visibility: isNotifyToAll === 1 ? 'hidden' : 'visible' }}>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Số điện thoại</Form.Label>
            <Form.Control required type="text" placeholder="Nhập số điện thoại" onChange={getUser} />
          </Form.Group>
        </Col>
      </Row>
      {detectedUser &&
        <Alert variant="success">
          {detectedUser.name} - {detectedUser.phone.replace('+84', '0')}
        </Alert>
      }
    </Form>
  );
};
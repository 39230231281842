import React from "react";
import { Button, Modal } from '@themesberg/react-bootstrap';

export default (props) => {

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: 'normal', fontSize: 15 }} id="contained-modal-title-vcenter">
          {props['title'] && props['title']}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img alt='' height={700} src={props.image} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

import axios from 'axios'
import { host } from '../../helper/config'

const PLUGINS_NAME = 'admin-accounts'

export const getAllAccounts = async (token) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-all`, {}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const addAccountAPI = async (token, name, bankId, accountName, accountNumber) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/add`,
        {
            name: name,
            bankId: bankId,
            accountName: accountName,
            accountNumber: accountNumber
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data
    }
    return null
}

export const updateStatus = async (token, id, newStatus) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/update-status`, {
        id: id,
        status: newStatus
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const changeEnableAuto = async (token, id, newStatus) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/set-auto-charge`, {
        id: id,
        status: newStatus
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}
import React, { useCallback, useContext, useRef, useState } from 'react'
import { Col, Row, Card, Form, Container, Button } from '@themesberg/react-bootstrap'
import { Context } from '../User'
import PinModal from '../sub-screen/pin-modal/PinModal'

const _FilterForm = () => {

  const { state, dispatch } = useContext(Context);

  const _phone = useRef(state.phone);

  const [modalShow, setModalShow] = useState(false);

  const _handleChangePhone = useCallback((payload) => {
    _phone.current = payload.target.value
  }, [])

  const _submitForm = useCallback(() => {
    dispatch({
      type: 'SUBMIT_FILTER',
      payload: {
        phone: _phone.current,
      }
    })
  }, [dispatch])

  const assignPin = () => {
    if (_phone.current.length === 10) {
      setModalShow(true)
    } else {
      alert('Số điện thoại không đúng')
    }
  }

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col className='align-self-center'>
              <h5>KHÁCH HÀNG</h5>
            </Col>
            <Col className='text-right'>
              <Button style={{ marginRight: 20 }} variant="info" type="submit" onClick={_submitForm}>Tìm kiếm</Button>
              <Button style={{ marginRight: 20 }} variant="success" type="submit" onClick={assignPin}>Cấp mã pin</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Container>
            <Row>
              <Col>
                <Row>
                  <Col md={3} className='text-right align-self-center'>
                    <Form.Label>Số điện thoại</Form.Label>
                  </Col>
                  <Col md={9}>
                    <Form.Control onChange={_handleChangePhone} required type="text" placeholder="Nhập số điện thoại (Để trống để tìm tất cả)" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <PinModal
        show={modalShow}
        phone={_phone.current}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default React.memo(_FilterForm)
import axios from 'axios'
import { host } from '../../helper/config'

export const login = async (email, password) => {

    // var salt = bcrypt.genSaltSync(5);
    // var hash = bcrypt.hashSync(password, salt);

    const response = await axios.post(host + '/admin-lottmekong-login', {
        email,
        password
    },
    {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        } })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const logout = async (token) => {

    const response = await axios.post(host + '/admin-lottmekong-logout', {}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: token
          }
    })

    if (response && response.status === 200) {
        return response.data.token || ''
    }
    return ''
}

export const checkToken = async (token) => {

    try {
        const response = await axios.post(host + '/admin-lottmekong-check-token', {}, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: token
              }
        })
    
        if (response && response.status === 200) {
            return response.data || null
        }
        return null
    } catch (error) {
        return null
    } 
}

// export const register = async () => {
//     const response = await axios.post(host + '/admin-lottmekong-register', {
//         headers: {
//             'Access-Control-Allow-Origin': '*',
//             'Content-Type': 'application/json',
//         },
//     })

//     if (response && response.status === 200) {
//         return response.data || {}
//     }
//     return {}
// }
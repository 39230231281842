// export const host = 'https://prodlottmekongabcdhbbgl.vesomyviet.vn';
export const host = 'https://backendtest.vesomyviet.vn';
// export const host = 'http://localhost:5785';

export const RSA_PUBLIC_KEY = '-----BEGIN PUBLIC KEY-----\n'+
'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAhEKxDENVH8MN/w1uPpAN\n'+
'TDGoLP9a4BefeocwSWE1AZGPROKvNeOnyhoqVK04lCW5IPsRt2KiRPEZ9u56rIqp\n'+
'IpNEo3cVwLwFTZz0iLi1eBCsZzrc1j1uXQwM8I/037Qrc3jlTGNzv2r6wSCNzTwo\n'+
'V6VCAGEx7oeM2W+ozvJP7IWlmJLH9cZdeJLDLuAwpssDQ82gA1S3m6GvJkhMjhUb\n'+
'5iarhc1zPM49jEOXEWn40amGQKCYcjsiZzz+3a0PIhDfAWqXYfNyx9gg+tA8QVQa\n'+
'bNdkZVBZG7KrfbtsJX4GAZR261/pUfA25AzRnkQFE0S0COboGp+9xBBLtEBAFrCh\n'+
'ywIDAQAB\n'+
'-----END PUBLIC KEY-----';
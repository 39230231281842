import axios from 'axios'
import { host } from '../../helper/config'
import { ITEM_PER_PAGE } from '../../helper/helper'

const PLUGINS_NAME = 'admin-withdraw'

// GET LIST WITHDRAW
export const getListWithdraw = async (token, query, page) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/get-list-request`,
        {
            ...query,
            page: page,
            itemPerPage: ITEM_PER_PAGE
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

// GET LIST WITHDRAW REQUEST
export const getListWithdrawRequest = async (token, query, page) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/get-list-request`,
        {
            ...query,
            page: page,
            itemPerPage: ITEM_PER_PAGE
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

// GET SUMARY WITHDRAW
export const getSummaryWithdraw = async (token, query) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/get-summary-request`,
        {
            ...query,
            page: 0,
            itemPerPage: 0
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

// UPDATE WITHDRAW
export const updateWithdraw = async (token, id, status, message) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/update-request`,
        {
            id: id,
            status: status,
            message: message
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data
    }
    return null
}

import axios from 'axios'
import { host } from '../../../helper/config'
import { ITEM_PER_PAGE } from '../../../helper/helper'

const PLUGINS_NAME = 'admin-classic-result'

export const getList = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-list`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const addResult = async (token, resultId, result) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/add`, {
        resultId: resultId,
        result: result
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const calculateReward = async (token, numberTickets, resultId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/calculate-reward`, {
        resultId: resultId,
        numberTickets: numberTickets
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const calculateRewardUnmarketable = async (token, numberTickets, resultId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/calculate-reward-unmarketable`, {
        resultId: resultId,
        numberTickets: numberTickets
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const stopSales = async (token, resultId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/stop-sales`, {
        resultId: resultId
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const getSolvedCart = async (token) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-list-channel-solved-cart`, {

    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

import axios from 'axios'
import { host } from '../../../helper/config'
import { ITEM_PER_PAGE } from '../../../helper/helper'

const PLUGINS_NAME = 'admin-classic-tickets'

export const getListClassicInputTicket = async (token, query, page, itemPerPage) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-list-input-ticket`, {
        ...query,
        page: page,
        itemPerPage: itemPerPage || ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const updateClassicInputTicket = async (token, id, numbers, amount, isSingle) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/update-numbers`, {
        id: id,
        numbers: numbers,
        isSingle: isSingle,
        amount: amount
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const getListClassicSalesTicket = async (token, query, page, itemPerPage) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-list-sales-ticket`, {
        ...query,
        page: page,
        itemPerPage: itemPerPage || ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const deleteAllTicket = async (token, inputId) => {
    if (window.confirm("Bạn có muốn xóa tất cả vé không?")) {
        const response = await axios.post(`${host}/${PLUGINS_NAME}/delete-all-ticket`, {
            inputId: inputId
        }, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
        if (response && response.status === 200) {
            return true
        }
        else
        {
            return null
        }
    }
    
    return null
}

export const deleteTicket = async (token, storageId) => {
    if (window.confirm("Bạn có muốn xóa vé của dãy số này không?")) {
        const response = await axios.post(`${host}/${PLUGINS_NAME}/delete-ticket`, {
            storageId: storageId
        }, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
        if (response && response.status === 200) {
            return true
        }
        else
        {
            return null
        }
    }
    
    return null
}

export const updateStatusTicket = async (token, storageId, status) => {
    if (window.confirm("Bạn có muốn "+(status === 6 ? "tạm ngưng" : "kích hoạt")+" vé của dãy số này không?")) {
        const response = await axios.post(`${host}/${PLUGINS_NAME}/update-status-ticket`, {
            storageId: storageId,
            status : status
        }, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
        if (response && response.status === 200) {
            return true
        }
        else
        {
            return null
        }
    }
    return null
}

export const updateTicketNumber = async (token, id, number) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/update-ticket-number`, {
        id: id,
        number: number,
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}
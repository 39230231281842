import React from "react";
import { Table } from '@themesberg/react-bootstrap';

const TableRow = (props) => {
  const { rIndex, name, value, amount } = props;
  return (
    <tr>
      <td>
        {rIndex + 1}
      </td>
      <td>
        {name}
      </td>
      <td>{new Intl.NumberFormat('vn-VI', {}).format(value) || ""}</td>
      <td className="text-center">
        {amount}
      </td>
      <td>{new Intl.NumberFormat('vn-VI', {}).format(value * amount) || ""}</td>
    </tr>
  );
};

export default ({ rewards, rewardNumber }) => {

  const _rewardNumber = rewardNumber ? JSON.parse(rewardNumber) : {}

  const list = rewards.map(it => {
    return {
      name: it.name,
      value: it.value,
      amount: _rewardNumber[it.id]
    }
  })

  return (
    <Table responsive className="align-items-center table-bordered table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col">STT</th>
          <th scope="col">Giải thưởng</th>
          <th scope="col">Giá trị</th>
          <th scope="col">Số lượng giải</th>
          <th scope="col">Tổng giá trị</th>
        </tr>
      </thead>
      <tbody>
        {list && list.map((pv, index) => <TableRow rIndex={index} key={`rows-keys-lottmekong-${pv.id}`} {...pv} />)}
      </tbody>
    </Table>
  );
}

import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from '@themesberg/react-bootstrap';
import { useCookies } from "react-cookie";
import { getWorkTimeByEmployeeId } from "../../../../../controller/api/employee";
import moment from "moment-timezone";

const TableRow = (props) => {
  const { startTime, endTime, device, tickets, gameId } = props;

  const completedWorkTime = tickets.filter(it => it.status === 3 || it.status === 4 || it.status === 5)

  return (
    <tr>
      <td>
        {moment(startTime).format("DD/MM/YYYY HH:mm:ss")}
      </td>
      <td>
        {endTime ? moment(endTime).format("DD/MM/YYYY HH:mm:ss") : ''}
      </td>
      <td>
        {device ? device.code : ''}
      </td>
      <td>
        {gameId}
      </td>
      <td>
        {completedWorkTime.length}
      </td>
    </tr>
  );
};

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [workTime, setWorkTime] = useState([])

  useEffect(() => {
    const getWorkTimeFunc = async () => {
      try {
        setWorkTime([])
        const wt = await getWorkTimeByEmployeeId(cookies.lottmekong, props.employeeId)

        if (wt) {
          setWorkTime(wt || [])
        }
      } catch (e) {
        alert('Người dùng ko tồn tại, không thể lấy thông tin tài khoản')
      }
    }
    if (props.employeeId && props.show) {
      getWorkTimeFunc()
    }
  }, [props.employeeId, cookies.lottmekong, props.show])

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.employeeName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered style={{ textAlign: 'center', verticalAlign: 'middle' }}>
          <thead style={{ backgroundColor: 'gray', color: 'white' }}>
            <tr>
              <th>Bắt đầu</th>
              <th>Kết thúc</th>
              <th>USB</th>
              <th>Game</th>
              <th>Số vé đã in</th>
            </tr>
          </thead>
          <tbody>
            {workTime && workTime.map((pv, index) => <TableRow key={`worktime-status-${index}`} {...pv} />)}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

import React from 'react'
import { Button } from '@themesberg/react-bootstrap';

export const TableRow = (props) => {
  const { user, id, _count, onDelete, isSuperAgent } = props;

  return (
    <tr className='text-center'>
      <td>
        {(_count.SecondLevelAgent === 0 && !isSuperAgent) && <Button variant="danger" className="m-1" onClick={() => onDelete(id, user.name)}>Xóa</Button>}
      </td>
      <td><b>{id}</b></td>
      <td>
        <span>{user.name || ""}</span><br />
        <span className={`fw-900 text-info`}>{user.identify || ""}</span><br />
        {user.phone}
      </td>
      <td className='text-center'>{_count.SecondLevelAgent}</td>
    </tr>
  );
};
import axios from 'axios'
import { host } from '../../helper/config'

const PLUGINS_NAME = 'gpaywallet'

// GET LIST WITHDRAW
export const checkGPayBalance = async (token) => {
    const response = await axios.get(
        `${host}/${PLUGINS_NAME}/enquiry-account`,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

// GET LIST WITHDRAW REQUEST
export const transferToGPayWallet = async (token, amount, message, phone, transactionId) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/transfer-to-wallet`,
        {
            amount: amount,
            message: message,
            phone: phone,
            transactionId: transactionId
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data.status || {status: 'SERVER_ERROR'}
    }
    return {status: 'SERVER_ERROR'}
}

// GET SUMARY WITHDRAW
export const getSummaryWithdraw = async (token, query) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/get-summary-request`,
        {
            ...query,
            page: 0,
            itemPerPage: 0
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

// UPDATE WITHDRAW
export const updateWithdraw = async (token, id, status, message) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/update-request`,
        {
            id: id,
            status: status,
            message: message
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data
    }
    return null
}

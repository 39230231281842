import { Button } from '@themesberg/react-bootstrap';
import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { name, AppVersion, identify, phone, onShowUserAmount, createdAt, amount, lastBuyTicketAt } = props;

  return (
    <tr className='text-center'>
      <td>
        <Button variant={amount === null ? "danger" : "info"} className="m-1" onClick={() => onShowUserAmount(phone)}>Xem tài khoản</Button>
      </td>
      <td>
        <span>{name || ""}</span><br />
        <span className={`fw-900 text-info`}>{identify || ""}</span>
      </td>
      <td>{phone}</td>
      <td>
        {moment(lastBuyTicketAt || '').format('DD/MM/YYYY HH:mm:ss')}
      </td>
      <td>
        {moment(createdAt || '').format('DD/MM/YYYY HH:mm:ss')}
      </td>


      <td>{AppVersion ? AppVersion.platform : ''}</td>
      <td>{AppVersion ? AppVersion.code : ''}</td>
    </tr>
  );
};
import { Button, Form } from '@themesberg/react-bootstrap';
import React from 'react'

export const AddTicketTableRow = (props) => {
  const { rIndex, isSingle, numbers, amount, onDelete, onChangeAmount, onChangeNumbers } = props;
  console.log(1)
  return (
    <tr>
      <td className="text-center">
        {rIndex + 1}
      </td>
      <td className="text-center">
        <Form.Control required type="number" defaultValue={numbers} placeholder="" onChange={(evt) => onChangeNumbers(rIndex, evt.target.value)} />
      </td>
      <td className="text-center">
        <Form.Check type="checkbox" defaultChecked={!isSingle} />
      </td>
      <td className="text-center">
        {isSingle ?
          <Form.Control required type="number" defaultValue={`${amount}`} placeholder="" onChange={(evt) => onChangeAmount(rIndex, parseInt(evt.target.value))} />
          :
          amount
        }
      </td>
      <td className="text-center">
        <Button variant='danger' onClick={() => onDelete(rIndex)}>
          Xóa
        </Button>
      </td>
    </tr>
  );
};
import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import { getSystemMaintained, setSystemMaintained } from "../controller/api/systemApi";

export default (props) => {

  const [isMaintained, setIsMaintained] = useState(undefined);

  const [cookies] = useCookies(['lottmekong']);

  const handleChange = useCallback(async () => {
    const resp = await setSystemMaintained(cookies.lottmekong, !isMaintained)
    if (resp) {
      setIsMaintained(!isMaintained)
    }
  }, [isMaintained, cookies.lottmekong])

  const getInitState = useCallback(async () => {
    const newMaintain = await getSystemMaintained(cookies.lottmekong)
    setIsMaintained(newMaintain)
  }, [cookies.lottmekong])

  useEffect(() => {
    getInitState()
  }, [getInitState])

  return (
    <div className="form-check form-switch">
      <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={!isMaintained} onChange={handleChange} />
      <label className={`form-check-label text-${!isMaintained ? 'success' : 'danger'}`} htmlFor="flexSwitchCheckChecked">{!isMaintained ? 'Hệ thống hoạt động bình thường' : 'Hệ thống đang bảo trì'}</label>
    </div>
  );
};

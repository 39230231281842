import React, { useCallback, useMemo } from 'react'

export default ({ totalPage, currentPage, onPageChange }) => {

  const _onPageChange = useCallback((index) => {
    if (currentPage === index) {
      return;
    } else {
      onPageChange(index)
    }
  }, [currentPage, onPageChange])

  const listPage = useMemo(() => {

    const lastPage = totalPage - 1

    const firstIndex = currentPage - 5 >= 0 ? currentPage - 5 : 0
    
    const lastIndex = currentPage + 5 > lastPage ? lastPage : currentPage + 5

    let index = firstIndex

    let pages = []

    while (index <= lastIndex && index >= firstIndex) {
      const i = index
      pages.push(
        <li key={index} className={`page-item ${index === currentPage ? 'active' : ''}`}>
          <a className="page-link" onClick={() => { _onPageChange(i) }} href={() => false}>{i + 1}</a>
        </li>
      )
      index = index + 1
    }
    return pages
  }, [totalPage, currentPage, _onPageChange])

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination pagination-sm mb-0">
        <li className={`page-item ${currentPage === 0 ? 'disabled' : ''}`}>
          <a className="page-link" onClick={() => { _onPageChange(0) }} href={() => false} >{'<<'}</a>
        </li>
        <li className={`page-item ${currentPage === 0 ? 'disabled' : ''}`}>
          <a className="page-link" onClick={() => { _onPageChange(currentPage - 1) }} href={() => false}>{'<'}</a>
        </li>
        {listPage}
        <li className={`page-item ${totalPage === 0 || (currentPage === totalPage - 1) ? 'disabled' : ''}`}>
          <a className="page-link" onClick={() => { _onPageChange(currentPage + 1) }} href={() => false}>{'>'}</a>
        </li>
        <li className={`page-item ${totalPage === 0 || (currentPage === totalPage - 1) ? 'disabled' : ''}`}>
          <a className="page-link" onClick={() => { _onPageChange(totalPage - 1) }} href={() => false}>{'>>'}</a>
        </li>
      </ul>
    </nav>
  );
};
import React, { useEffect, useState } from "react";
import { Button, Modal } from '@themesberg/react-bootstrap';
import { useCookies } from "react-cookie";
import { assignPin } from "../../../../../controller/api/user";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [pin, setPin] = useState('')

  useEffect(() => {
    const getUserFunc = async () => {
      try {

        const pin = await assignPin(cookies.lottmekong, props.phone.replace('0', '+84'))

        setPin(pin)

      } catch (e) {
        alert('Lỗi')
      }
    }
    if (props.phone && props.show) {
      getUserFunc()
    }
  }, [props.phone, cookies.lottmekong, props.show])

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Số điện thoại: {props.phone}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1>{pin}</h1>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="success" onClick={syncAmount}>Xem lịch sử tài khoản</Button> */}
        <Button variant="danger" onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

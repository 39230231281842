import React, { useEffect, useState } from 'react'
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap'
import { getAll } from '../../../../controller/api/__classic__/listReward';
import { useCookies } from 'react-cookie';

const TableRow = (props) => {
  const { name, description, value, amount } = props;

  return (
    <tr>
      <td>{name || ""}</td>
      <td>{description || ""}</td>
      <td>{new Intl.NumberFormat('vn-VI', { }).format(amount)}</td>
      <td>{new Intl.NumberFormat('vn-VI', { }).format(value)}</td>
    </tr>
  );
};

export const AccountTable = () => {

  const [listAccount, setListAccount] = useState({})

  const [cookies] = useCookies(['lottmekong'])

  useEffect(() => {
    const getAllAccountFunc = async () => {
      const list = await getAll(cookies.lottmekong, 0)

      if (list) {
        const res = {
          data: list.data,
          total: list.total,
        }
        setListAccount(res || {})
      }
    }
    getAllAccountFunc()
  }, [cookies.lottmekong])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Danh sách giải thưởng ({listAccount.total})</h5>
            </Col>
          </Row>
        </Card.Header>
        <Table responsive className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">Tên</th>
              <th scope="col">Mô tả</th>
              <th scope="col">Số giải</th>
              <th scope="col">Giá trị</th>
            </tr>
          </thead>
          <tbody>
            {listAccount.data && listAccount.data.map(pv => <TableRow key={`device-status-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
      </Card>
    </>
  );
};
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

export const TableRow = (props) => {
  const { id, outputDate, amount } = props;

  const linkTo = `/classic/ticket-storage-output?outputId="${props.status}${id}"`

  const [status] = useState(props.status)

  return (
    <tr className='text-center'>
      <td>
      <Button>
            <Link
              target={'_blank'}
              style={{ color: '#fff' }}
              to={linkTo}
              key={id}
            >
              {'Xem vé'}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
            </Link>
          </Button>
      </td>
      <td style={{ width: 250 }}>
        {amount}
      </td>
      <td style={{ width: 250 }}>
        {status === 0 ? 'Đang xử lý' : 'Xong'}
      </td>
      <td>{moment(outputDate || '').format('DD/MM/YYYY HH:mm').toLocaleString()}</td>
    </tr>
  );
};
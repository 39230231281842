import React, { useState } from "react";
import { Col, Row, Form, Alert } from '@themesberg/react-bootstrap';
import { getUserByPhone } from "../../../../../../controller/api/user";
import { useCookies } from "react-cookie";
import { findAgent } from "../../../../../../controller/api/agent";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [detectedUser, setDetectedUser] = useState(null)

  const [detectedFirstAgent, setDetectedFirstAgent] = useState(null)

  const [detectedFirstAgentByPhone, setDetectedFirstAgentByPhone] = useState(null)

  const getUser = async (evt) => {
    if (evt.target.value.length === 10) {
      const phone = evt.target.value.replace('0', '+84')

      let response = await getUserByPhone(cookies.lottmekong, phone)

      if (response) {
        let isAvailable = !response.FirstLevelAgent && !response.SecondLevelAgent && !response.Collaborator;

        if (isAvailable) {
          props.setUser(response.id)
        } else {
          props.setUser(null)
        }
        response.isAvailable = isAvailable
        setDetectedUser(response)
      }
    } else if (evt.target.value.length > 10) {
      return false
    }
  }

  const getAgent = async (evt) => {
    let keyword = evt.target.value
    if (keyword.length === 6) {

      let response = await findAgent(cookies.lottmekong, 1, keyword)

      if (response && response.data) {
        props.setFirstAgent(response.data.id)
        setDetectedFirstAgentByPhone(null)
        setDetectedFirstAgent(response.data)
      } else {
        props.setFirstAgent('')
      }
    }
    return false
  }

  const getAgentByPhone = async (evt) => {
    let keyword = evt.target.value
    if (keyword.length === 10) {
      keyword = evt.target.value

      let response = await findAgent(cookies.lottmekong, 1, keyword)

      if (response && response.data) {
        props.setFirstAgent(response.data.id)
       
        setDetectedFirstAgent(null)
        setDetectedFirstAgentByPhone(response.data)
      } else {
        props.setFirstAgent('')
      }
    }
    return false
  }

  const onCodeChange = (evt) => {
    props.setCode(evt.target.value)
  }

  return (
    <Form>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="code">
            <Form.Label>Mã đại lý</Form.Label>
            <Form.Control required type="text" placeholder="Nhập mã đại lý (Để trống nếu muốn tạo mã ngẫu nhiên)" onChange={onCodeChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Số điện thoại</Form.Label>
            <Form.Control required type="text" placeholder="Nhập số điện thoại" onChange={getUser} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {detectedUser &&
          <Col md={12} className="mb-2">
            <Alert variant={detectedUser.isAvailable ? 'success' : 'danger'}>
              {detectedUser.name} - {detectedUser.phone.replace('+84', '0')} <br /> {detectedUser.FirstLevelAgent ? 'Đại lý cấp 1' : detectedUser.SecondLevelAgent ? 'Đại lý cấp 2' : detectedUser.Collaborator ? 'Khách hàng đại lý' : 'Khách hàng tự do'}
            </Alert>
          </Col>
        }
      </Row>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Số điện thoại đại lý cấp 1</Form.Label>
            <Form.Control required type="text" placeholder="Nhập số điện thoại đại lý cấp 1" onChange={getAgentByPhone} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {detectedFirstAgentByPhone &&
          <Col md={12} className="mb-2">
            <Alert variant={'success'}>
              {detectedFirstAgentByPhone.user.name} - {detectedFirstAgentByPhone.user.phone.replace('+84', '0')}
            </Alert>
          </Col>
        }
      </Row>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Mã đại lý cấp 1</Form.Label>
            <Form.Control required type="text" placeholder="Nhập mã đại lý cấp 1" onChange={getAgent} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {detectedFirstAgent &&
          <Col md={12} className="mb-2">
            <Alert variant={'success'}>
              {detectedFirstAgent.user.name} - {detectedFirstAgent.user.phone.replace('+84', '0')}
            </Alert>
          </Col>
        }
      </Row>
    </Form>
  );
};
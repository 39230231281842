import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { reverseClassicCartStatus } from '../../../../controller/api/__classic__/cart';

export const TableRow = (props) => {
  const { user, createdAt, status, tickets, id, images } = props;

  const [cookies] = useCookies(['lottmekong'])

  const [statusState, setStatusState] = useState(status)

  const linkTo = `/classic/ticket?cartId="${id}"`

  const datedString = moment(createdAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()

  let amount = 0;

  for (let index = 0; index < tickets.length; index++) {
    const element = tickets[index];
    amount += element.amount
  }

  const onReverse = useCallback(async (cartId) => {

    const resp = await reverseClassicCartStatus(cookies.lottmekong, cartId)
    if (resp) {
      setStatusState(2)
    } else {
      alert('Có lỗi xảy ra')
      return false
    }
  },[cookies.lottmekong])

  const actionTd = useMemo(() => {
    switch (statusState) {
      case 2:
        return <Button>
          <Link
            target={'_blank'}
            style={{ color: '#fff' }}
            to={linkTo}
            key={id}
          >
            {'Xem vé'}
            <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
          </Link>
        </Button>
      case 3:
        return <>
          <Button>
            <Link
              target={'_blank'}
              style={{ color: '#fff' }}
              to={linkTo}
              key={id}
            >
              {'Xem vé'}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
            </Link>
          </Button> <Button variant='warning' className="m-1" onClick={() => onReverse(id)}>Xuất vé lại</Button></>
      default:
        return '';
    }
  }, [statusState, id, linkTo, onReverse])

  return (
    <tr>
      <td className="text-center">
        {actionTd}
      </td>
      <td className="text-center">{id}</td>
      <td>
        {(user && user.name) || ""}<br />
        <span><strong className={`text-primary`}>{(user && user.phone) || ""}</strong></span>
      </td>
      <td className="text-center">{amount}</td>
      <td className="text-center">{statusState === 2 ? 'Đang xử lý' : 'Đã hoàn thành'}</td>
      <td className="text-center">{images && images[0] &&
        <img
          alt = ''
          height={200}
          src={images[0]}
          onClick={() => {
            props.handleShowImagePreview('Ảnh 1', images[0])
          }}
        />
      }
        &nbsp;
        {images && images[1] &&
          <img
            alt = ''
            height={200}
            src={images[0]}
            onClick={() => {
              props.handleShowImagePreview('Ảnh 2', images[1])
            }}
          />
        }</td>
      <td>{datedString}</td>
    </tr>
  );
};
import React, { useMemo } from "react";
import { Button, Modal, Table } from '@themesberg/react-bootstrap';

export default (props) => {

  const result = useMemo(() => {
    if (props.result) {
      const listPrizes = props.result

      return listPrizes.map(e => e.split(' '))
    }
    return []
  }, [props.result])

  if (result.length === 0) {
    return null;
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.gamePeriod && 'Kết quả kỳ: #' + props.gamePeriod.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered style={{ textAlign: 'center', verticalAlign: 'middle' }}>
          <thead style={{backgroundColor: 'gray', color: 'white'}}>
            <tr>
              <th>Giải</th>
              <th colSpan={12}>Bộ số</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nhất</td>
              <td colSpan={6}>{result[0][0]}</td>
              <td colSpan={6}>{result[0][1]}</td>
            </tr>
            <tr>
              <td>Nhì</td>
              <td colSpan={3}>{result[1][0]}</td>
              <td colSpan={3}>{result[1][1]}</td>
              <td colSpan={3}>{result[1][2]}</td>
              <td colSpan={3}>{result[1][3]}</td>
            </tr>
            <tr>
              <td rowSpan={3}>Ba</td>
              <td colSpan={6}>{result[2][0]}</td>
              <td colSpan={6}>{result[2][1]}</td>
            </tr>
            <tr>
              <td colSpan={6}>{result[2][2]}</td>
              <td colSpan={6}>{result[2][3]}</td>
            </tr>
            <tr>
              <td colSpan={6}>{result[2][4]}</td>
              <td colSpan={6}>{result[2][5]}</td>
            </tr>
            <tr>
              <td rowSpan={2}>Tư</td>
              <td colSpan={3}>{result[3][0]}</td>
              <td colSpan={3}>{result[3][1]}</td>
              <td colSpan={3}>{result[3][2]}</td>
              <td colSpan={3}>{result[3][3]}</td>
            </tr>
            <tr>
              <td colSpan={3}>{result[3][4]}</td>
              <td colSpan={3}>{result[3][5]}</td>
              <td colSpan={3}>{result[3][6]}</td>
              <td colSpan={3}>{result[3][7]}</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

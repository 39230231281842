import React, { useEffect, useState } from 'react'
import { Col, Row, Card, Table, Badge, Form, InputGroup, Button } from '@themesberg/react-bootstrap'
import { getAllGames, updateOrderNumber, updateStatus } from '../../../../controller/api/game';
import { useCookies } from 'react-cookie';
import UpdateGuide from '../sub-screen/update-guide/UpdateGuide';

const TableRow = (props) => {
  const { name, status, jackpot1, jackpot2, orderNumber, onStatusChange, onOrderNumberChange, id, guide, onGuidePress } = props;

  return (
    <tr>
      {/* <td>
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
            <span className="icon icon-sm">
              <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <FontAwesomeIcon icon={faEye} className="me-2" /> Chi tiết
            </Dropdown.Item>
            <Dropdown.Item>
              <FontAwesomeIcon icon={faEdit} className="me-2" /> Chỉnh sửa
            </Dropdown.Item>
            <Dropdown.Item className="text-danger">
              <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Xóa
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td> */}
      <th scope="row">{name}  <span className={`fw-900 text-info`}>{id}</span></th>
      <td className="text-right">
        <span className="text-success">{jackpot1 ? new Intl.NumberFormat('vn-VI', {}).format(jackpot1) : ""}</span><br />
        <span className="fs-10 text-info">{jackpot2 ? new Intl.NumberFormat('vn-VI', {}).format(jackpot2) : ""}</span>
      </td>
      <td className="text-center" style={{ width: 10, textAlign: 'center' }}>
        <InputGroup>
          <Form.Control style={{ textAlign: 'right' }} type="number" defaultValue={orderNumber} onChange={(evt) => onOrderNumberChange(evt, id)} />
        </InputGroup>
      </td>
      <td><Button variant="primary" className="m-1" onClick={() => onGuidePress(id, name, guide)}>Xem hướng dẫn</Button></td>

      <td style={{ width: 250 }}>
        <Form.Select value={status} onChange={(evt) => onStatusChange(evt, id)} >
          <option value={0}>Không hoạt động</option>
          <option value={1}>Hoạt động</option>
        </Form.Select>
      </td>
    </tr>
  );
};

export const GameTable = () => {

  const [cookies] = useCookies(['lottmekong'])

  const [listGame, setListGame] = useState({})

  const [modalShow, setModalShow] = useState(false);

  const [currentGameName, setCurrentGameName] = useState('');
  
  const [currentGameId, setCurrentGameId] = useState('');
  
  const [currentGameGuide, setCurrentGameGuide] = useState(null);

  useEffect(() => {
    const getAllGameFunc = async () => {
      const list = await getAllGames(cookies.lottmekong)

      if (list) {
        const res = {
          data: list.data,
          total: list.total,
          isOff: list.data.filter(item => item.status === 0).length,
          isOn: list.data.filter(item => item.status === 1).length,
        }
        setListGame(res || {})
      }
    }
    getAllGameFunc()
  }, [cookies.lottmekong])

  const onGuidePress = (id, name, guide) => {
    setCurrentGameId(id)
    setCurrentGameName(name)
    setCurrentGameGuide(guide)
    setModalShow(true)
  }

  const onStatusChange = async (evt, id) => {
    const newStatus = evt.target.value
    const resp = await updateStatus(cookies.lottmekong, id, newStatus)
    if (resp) {
      window.location.reload()
    } else {
      alert('Có lỗi xảy ra')
      return false
    }
  }

  const onOrderNumberChange = async (evt, id) => {
    const newOrderNumber = parseInt(evt.target.value)
    const resp = await updateOrderNumber(cookies.lottmekong, id, newOrderNumber)
    if (resp) {
      window.location.reload()
    } else {
      alert('Có lỗi xảy ra')
      return false
    }
  }

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Trò chơi ({listGame.total})</h5>
            </Col>
            <Col className="text-end">
              {listGame.isOff > 0 &&
                <Badge bg="danger" className="me-1">{listGame.isOff} không hoạt động </Badge>
              }
              {listGame.isOn > 0 &&
                <Badge bg="success" className="me-1">{listGame.isOn} đang hoạt động </Badge>
              }
            </Col>
          </Row>
        </Card.Header>
        <Table responsive className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              {/* <th scope="col">Thao tác</th> */}
              <th scope="col">Tên trò chơi <span className={`fw-900 text-info`}>Mã trò chơi</span></th>
              <th scope="col" className="text-right">Jackpot</th>
              <th scope="col" className="text-center">Số thứ tự</th>
              <th scope="col">Hướng dẫn</th>
              <th scope="col">Trạng thái</th>
            </tr>
          </thead>
          <tbody>
            {listGame.data && listGame.data.map(pv => <TableRow onGuidePress={onGuidePress} onStatusChange={onStatusChange} onOrderNumberChange={onOrderNumberChange} key={`device-status-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
      </Card>
      <UpdateGuide
        name={currentGameName}
        game={currentGameId}
        guide={currentGameGuide}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};
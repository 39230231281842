
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from '@themesberg/react-bootstrap';

import { PaymentMethodTable } from './partials/PaymentTable';

export default () => {

  useEffect(() => {
    document.title = "Hình thức thanh toán"
  }, []);

  return (
    <>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Thông tin chung</Breadcrumb.Item>
            <Breadcrumb.Item active>Hình thức thanh toán</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <PaymentMethodTable />
    </>
  );
};

import React, { useRef, useState } from "react";
import { Button, Modal, Toast } from '@themesberg/react-bootstrap';
import AddForm from "./partials/AddForm";
import { useCookies } from "react-cookie";
import { addCollaborator } from "../../../../../controller/api/agent";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [error, setError] = useState(false)

  const userRef = useRef('')

  const secondAgentRef = useRef('')

  const onUserChange = (text) => {
    userRef.current = text
  }

  const onSecondAgentChange = (text) => {
    secondAgentRef.current = text
  }

  const onSubmit = async () => {
    try {
      if (secondAgentRef.current.length > 0) {
      const resp = await addCollaborator(cookies.lottmekong, 'Khách hàng đại lý', userRef.current, secondAgentRef.current)
      if (resp) {
        window.location.reload()
      } else {
        alert('Có lỗi xảy ra')
        return false
      }
    } else {
      alert('Chưa chọn đại lý cấp 2')
    }
    } catch (e) {
      alert('error')
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Thêm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Toast className="d-inline-block m-1" onClose={() => setError(false)} show={error} delay={3000} autohide bg='danger' key={3} >
          <Toast.Header>
            <strong className="me-auto">Lỗi</strong>
          </Toast.Header>
          <Toast.Body>Không thêm được</Toast.Body>
        </Toast>
        <AddForm
          setSecondAgent={onSecondAgentChange}
          setUser={onUserChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
        <Button variant="primary" onClick={onSubmit}>Thêm</Button>
      </Modal.Footer>
    </Modal>
  );
}

import React, { useCallback, useEffect, useReducer, useState } from "react";

import Tabs from "./partials/Tabs";
import FilterForm from "./partials/FilterForm";
import { useCookies } from "react-cookie";
import { calculatePage } from "../../../helper/helper";
import { getAllFunc } from "./controller/WithdrawC";
import Summary from "./partials/Summary";
import ExportProcessingPopup from "./sub-screen/export-processing/ExportProcessingPopup";

export const Context = React.createContext(null);

const initialData = {
  filter: {
    from: new Date(),
    to: new Date(),
    phone: '',
    bankingPage: 0,
    internalPage: 0,
  },
  data: {
    internal: {
      list: [],
      total: 0,
      totalPage: 0
    },
    banking: {
      list: [],
      total: 0,
      totalPage: 0
    },
  },
  method: 'BANKING',
  filterPressCount: 0,
  exportPressCount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMIT_FILTER':
      return {
        ...state,
        filter: {
          ...action.payload,
          bankingPage: 0,
          internalPage: 0,
        },
        filterPressCount: state.filterPressCount + 1
      }
    case 'UPDATE_DATA_INTERNAL':
      return {
        ...state,
        data: {
          ...state.data,
          internal: {
            ...action.payload
          }
        }
      }
    case 'UPDATE_DATA_BANKING':
      return {
        ...state,
        data: {
          ...state.data,
          banking: {
            ...action.payload
          }
        }
      }
    case 'CHANGED_METHOD':
      return {
        ...state,
        method: action.payload
      }
    case 'EXPORT_DATA':
      return {
        ...state,
        exportPressCount: state.exportPressCount + 1
      }
    case 'UPDATE_PAGE_BANKING':
      return {
        ...state,
        filter: {
          ...state.filter,
          bankingPage: action.payload
        }
      }
    case 'UPDATE_PAGE_INTERNAL':
      return {
        ...state,
        filter: {
          ...state.filter,
          internalPage: action.payload
        }
      }
    default:
      return {
        ...state
      }
  }
}

export default () => {

  var [state, dispatch] = useReducer(reducer, initialData);

  const [showProcessingModal, setShowProcessingModal] = useState(false)

  const [cookies] = useCookies(['lottmekong'])

  const getBankingFunc = useCallback(async () => {
    getAllFunc(cookies, 'BANKING', state.filter.from, state.filter.to, state.filter.phone, state.filter.bankingPage, (response) => {

      dispatch({
        type: 'UPDATE_DATA_BANKING',
        payload: {
          list: response.data,
          total: response.total,
          totalPage: calculatePage(response.total)
        }
      })
    })
  }, [state.filter.from, state.filter.to, state.filter.bankingPage, state.filter.phone, cookies])

  const getInternalFunc = useCallback(async () => {

    getAllFunc(cookies, 'INTERNAL', state.filter.from, state.filter.to, state.filter.phone, state.filter.internalPage, (response) => {

      dispatch({
        type: 'UPDATE_DATA_INTERNAL',
        payload: {
          list: response.data,
          total: response.total,
          totalPage: calculatePage(response.total)
        }
      })
    })
  }, [state.filter.from, state.filter.to, state.filter.internalPage, state.filter.phone, cookies])

  useEffect(() => {
    getInternalFunc()
  }, [state.filterPressCount, state.filter.internalPage, getInternalFunc])

  useEffect(() => {
    getBankingFunc()
  }, [state.filterPressCount, state.filter.bankingPage, getBankingFunc])

  useEffect(() => {
    if (state.exportPressCount > 0) {
      setShowProcessingModal(true)
    }
  }, [state.exportPressCount])

  useEffect(() => {
    document.title = "Đổi thưởng"
  }, []);

  return (
    <Context.Provider value={{
      state,
      dispatch
    }}>

      <FilterForm />

      <br />

      <Summary />

      <Tabs />

      <ExportProcessingPopup
        total={state.data.banking.total + state.data.internal.total}
        show={showProcessingModal}
        onHide={() => setShowProcessingModal(false)}
      />
    </Context.Provider>
  );
};

import moment from 'moment';
import React from 'react'

export const InternalTableRow = (props) => {
  const { value, user, createdAt } = props;

  const datedString = moment(createdAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()

  return (
    <tr className='text-center'>
      <td>
        {(user && user.name) || ""}<br />
        <span ><strong className={`text-primary`}>{(user && user.phone) || ""}</strong></span>
      </td>
      <td className="align-items-right">{new Intl.NumberFormat('vn-VI', { }).format(value) || ""}</td>
      <td>{datedString}</td>
    </tr>
  );
};
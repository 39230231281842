import { Button } from '@themesberg/react-bootstrap';
import React, { useCallback, useState } from 'react'
import { useCookies } from 'react-cookie';
import { deleteIssueCharge } from '../../../../../controller/api/charge';

export const TableRow = (props) => {
  const { Value, id, PhoneNumber, TransactionDate, note } = props;

  const [isLoading, setIsLoading] = useState(false)

  const [isDeleted, setIsDeleted] = useState(false)

  const [cookies] = useCookies(['lottmekong'])

  const _onDelete = useCallback(async () => {
    const confirmed = window.confirm(`Bạn muốn xóa lỗi ${id}`)

    if (confirmed) {
      setIsLoading(true)
      const resp = await deleteIssueCharge(cookies.lottmekong, id)

      if (resp) {
        setIsDeleted(true)
      }
    }
  }, [cookies.lottmekong, id])

  return (
    <tr>
      <td>
        {
          isDeleted ?
            <td className='text-danger' style={{ width: 250 }}>
              Đã xóa
            </td> :
            isLoading ?
              <td className='text-danger' style={{ width: 250 }}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Đang xử lý</span>
                </div>
              </td> :
              <td style={{ width: 250 }}>
                <Button variant="danger" className="m-1" onClick={_onDelete}>Xóa</Button>
              </td>
        }
      </td>
      <td className="text-center">{id}</td>
      <td className="text-center">{PhoneNumber}</td>
      <td className="fw-900 text-success text-center">{new Intl.NumberFormat('vn-VI', {}).format(Value) || ""}</td>
      <td>{note}</td>
      <td className="text-center">{TransactionDate}</td>
    </tr>
  );
};

import React from 'react'
import { Button, Col, Row } from '@themesberg/react-bootstrap'
import { disableTicketRoll, enableTicketRoll } from '../../../../controller/api/systemApi';
import { useCookies } from 'react-cookie';

export const GameItem = ({ data }) => {

  const [cookies] = useCookies(['lottmekong']);

  const onEnableClick = () => {
    enableTicketRoll(cookies.lottmekong, data.game, false)
  }

  const onDisableClick = () => {
    disableTicketRoll(cookies.lottmekong, data.game, false)
  }

  return (
    <tr>
      <td className="text-center">
        {data.isRollEnabled ?
          <Col>
            <Button variant="danger" size="sm" onClick={onDisableClick}>Tắt chia vé</Button>
          </Col>
          :
          <Col>
            <Button variant="success" size="sm" onClick={onEnableClick}>Bật chia vé</Button>
          </Col>
        }
      </td>
      <td className="text-center"><b>{data.game.toUpperCase()}</b></td>
      <td className="text-center">{data.name}</td>
      <td className="text-center">{data.device}</td>
      <td className="text-center text-warning"><b>{data.notRoll}</b></td>
      <td className="text-center text-success">{data.waiting}</td>
      <td className="text-center text-danger">{data.deleted}</td>
      <td className="text-center">{data.printed}</td>
      <td className="text-center">{data.done}</td>
      <td className="text-center"><b>{data.total}</b></td>
      
    </tr>
  )
};
import axios from 'axios'
import { host } from '../../helper/config'
import { ITEM_PER_PAGE } from '../../helper/helper'

const PLUGINS_NAME = 'admin-users'

export const getAllUsers = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-all`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const getUserByPhone = async (token, phone) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-by-phone`, {
        phone: phone
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const getUserAmountByPhone = async (token, phone) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-amount-by-phone`, {
        phone: phone
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const updateStatus = async (token, id, newStatus) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/update-status`, {
        id: id,
        status: newStatus
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const assignPin = async (token, phone) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/assign-pin`, {
        phone: phone,
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || ''
    }
    return ''
}
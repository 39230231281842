import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { name, identify, phone, createdAt, AppVersion } = props;

  return (
    <tr className='text-center'>
      <td>
        <span>{name || ""}</span><br />
        <span className={`fw-900 text-info`}>{identify || ""}</span>
      </td>
      <td>{phone}</td>
      <td>{AppVersion ? AppVersion.platform : ''}</td>
      <td>{AppVersion ? AppVersion.code : ''}</td>
      <td>
        {moment(createdAt || '').format('DD/MM/YYYY HH:mm:ss')}
      </td>
    </tr>
  );
};
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { Col, Row, Card, Form, Container, Button } from '@themesberg/react-bootstrap'
import { Context } from '../Ticket'

const _FilterForm = () => {

  const { state, dispatch } = useContext(Context);

  const _cartId = useRef(state.cartId);
  const _ticketId = useRef(state.ticketId);

  useEffect(() => {
    _cartId.current = state.cartId
  }, [state.cartId])


  const _handleChangeCart = useCallback((payload) => {
    _cartId.current = payload.target.value
  }, [])

  const _handleChangeTicket = useCallback((payload) => {
    _ticketId.current = payload.target.value
  }, [])

  const _submitForm = useCallback(() => {
    if (_cartId.current !== '' || _ticketId.current !== '') {
      dispatch({
        type: 'SUBMIT_FILTER',
        payload: {
          cartId: _cartId.current,
          ticketId: _ticketId.current
        }
      })
    } else {
      alert('Bắt buộc nhập 1 trong 2 mã đơn và mã vé')
    }
  }, [dispatch])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col className='align-self-center'>
              <h5>DANH SÁCH VÉ</h5>
            </Col>
            <Col className='text-right'>
              <Button style={{ marginRight: 20 }} variant="info" type="submit" onClick={_submitForm}>Tìm kiếm</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Container>
            <Row>
              <Col>
                <Row>
                  <Col md={3} className='text-right align-self-center'>
                    <Form.Label>Mã đơn</Form.Label>
                  </Col>
                  <Col md={9}>
                    <Form.Control defaultValue={state.cartId} onChange={_handleChangeCart} required type="text" placeholder="Nhập mã đơn" />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col md={3} className='text-right align-self-center'>
                    <Form.Label>Mã vé</Form.Label>
                  </Col>
                  <Col md={9}>
                    <Form.Control onChange={_handleChangeTicket} required type="text" placeholder="Nhập mã vé" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_FilterForm)
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';
import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';

export const TableRow = (props) => {
  const { gameId, user, numberPeriod, createdAt, totalValue, period, tickets, id, transactionDate } = props;

  const linkTo = `/management/ticket?cartId="${id}"`

  const datedString = moment(createdAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()
  
  const transDatedString = transactionDate ? moment(transactionDate).format('DD/MM/YYYY HH:mm').toLocaleString() : ''

  // đếm số vé bị hủy

  const deletedTickets = tickets.filter(e => e.status === 6).length

  return (
    <tr>
      <td className="text-center">
        <Button>
          <Link
            target={'_blank'}
            style={{ color: '#fff' }}
            to={linkTo}
            key={id}
          >
            {'Xem vé'}
            <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
          </Link>
        </Button>
      </td>
      <td className="text-center">{id}</td>
      <td>
        {(user && user.name) || ""}<br />
        <span><strong className={`text-primary`}>{(user && user.phone) || ""}</strong></span>
      </td>
      <td className='text-center'>
        <img alt='' src={require(`../../../../assets/img/logo-${gameId}.png`)} className="img-thumbnail" width={90} height={30}></img>
      </td>
      {period ?
      <td className="text-center">{period.name}</td>
      :
      <td className="text-center text-danger">{'Không lấy được kỳ'}</td>
}
      <td className="text-center">{numberPeriod}</td>
      <td className="text-center">{deletedTickets}</td>
      {tickets.length > 0 ?
        <td className="text-center">{new Intl.NumberFormat('vn-VI', {}).format(tickets[0].value)}</td>
        :
        <td className="text-center text-danger">{'Không có vé'}</td>
      }
      <td className="text-center">{new Intl.NumberFormat('vn-VI', {}).format(totalValue) || ""}</td>
      <td>{datedString}</td>
      <td>{transDatedString}</td>
    </tr>
  );
};
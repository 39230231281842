import axios from 'axios'
import { host } from '../../helper/config'
import { ITEM_PER_PAGE } from '../../helper/helper'

const PLUGINS_NAME = 'admin-bank'

export const getAllBanks = async (token, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get`, {
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const update = async (token, id, name, shortName, status, logo) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/update`, {
        id: id,
        name: name,
        shortName: shortName,
        logo: logo,
        status: status
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const add = async (token, name, shortName, status, logo) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/add`, {
        name: name,
        shortName: shortName,
        logo: logo,
        status: status
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const deleteBank = async (token, id) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/delete`, {
        id: id
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}
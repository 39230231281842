import axios from 'axios'
import { host } from '../../../helper/config'
import { ITEM_PER_PAGE } from '../../../helper/helper'
import { encryptMessage } from '../../../helper/helper'
const PLUGINS_NAME = 'admin-classic-lottery-input'

export const getList = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-list`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const addInput = async (token, listData) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/add`, listData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const addTicketForInput = async (token, data) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/add-lottery-ticket-storage`, data, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const confirmInput = async (token, inputId, page) => {
    const data = {}; 
    data[ 'inputId' ] = inputId;       
    const jsonData = JSON.stringify(data);
    const response = await axios.post(`${host}/${PLUGINS_NAME}/confirm-classic-lottery-input`, {data:encryptMessage(
        jsonData)
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const deleteInput = async (token, inputId, page) => {
    const data = {}; 
    data[ 'inputId' ] = inputId;       
    const jsonData = JSON.stringify(data);
    const response = await axios.post(`${host}/${PLUGINS_NAME}/delete-classic-lottery-input`, {data:encryptMessage(
        jsonData)
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}
import React from "react";
import { Col, Row, Form } from '@themesberg/react-bootstrap';

export const AddNewEmployeeForm = (props) => {

  const onNameChange = (evt) => {
    props.setName(evt.target.value)
  }

  const onPasswordChange = (evt) => {
    props.setPassword(evt.target.value)
  }

  return (
    <Form>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>username</Form.Label>
            <Form.Control required type="text" placeholder="Nhập username" onChange={onNameChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Mật khẩu</Form.Label>
            <Form.Control required type="text" placeholder="Nhập mật khẩu" onChange={onPasswordChange} />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};
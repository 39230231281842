import React, { useEffect, useState } from 'react'
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap'
import { getAllChannel } from '../../../../controller/api/__classic__/channel';
import { useCookies } from 'react-cookie';

const parseWeekdayName = (day) => {
  switch (day) {
    case 'Mon':
      return 'Thứ hai'
    case 'Tue':
      return 'Thứ ba'
    case 'Wed':
      return 'Thứ tư'
    case 'Thu':
      return 'Thứ năm'
    case 'Fri':
      return 'Thứ sáu'
    case 'Sat':
      return 'Thứ bảy'
    case 'Sun':
      return 'Chủ nhật'
    default:
      return ''
  }
}

const TableRow = (props) => {
  const { name, region, weekdays, id } = props;

  const weekDayNames = weekdays.map(it => {
    return parseWeekdayName(it)
  })

  return (
    <tr>
      <td className='text-danger'>
        <strong>{id}</strong>
      </td>
      <td>{name || ""}</td>
      <td>{region || ""}</td>
      <td>{weekDayNames.join(' , ')}</td>
    </tr>
  );
};

export const AccountTable = () => {

  const [listAccount, setListAccount] = useState({})

  const [cookies] = useCookies(['lottmekong'])

  useEffect(() => {
    const getAllAccountFunc = async () => {
      const list = await getAllChannel(cookies.lottmekong, 0)

      if (list) {
        const res = {
          data: list.data,
          total: list.total,
        }
        setListAccount(res || {})
      }
    }
    getAllAccountFunc()
  }, [cookies.lottmekong])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Danh sách kênh ({listAccount.total})</h5>
            </Col>
          </Row>
        </Card.Header>
        <Table responsive className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">Mã</th>
              <th scope="col">Tên</th>
              <th scope="col">Khu vực</th>
              <th scope="col">Lịch quay số</th>
            </tr>
          </thead>
          <tbody>
            {listAccount.data && listAccount.data.map(pv => <TableRow key={`device-status-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
      </Card>
    </>
  );
};
import React, { useState } from "react";
import { Col, Row, Form, Alert } from '@themesberg/react-bootstrap';
import { getUserByPhone } from "../../../../../../controller/api/user";
import { useCookies } from "react-cookie";
import { findAgent } from "../../../../../../controller/api/agent";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [detectedUser, setDetectedUser] = useState(null)

  const [detectedSecondAgent, setDetectedSecondAgent] = useState(null)

  const [detectedSecondAgentByPhone, setDetectedSecondAgentByPhone] = useState(null)

  const getUser = async (evt) => {
    if (evt.target.value.length === 10) {
      const phone = evt.target.value.replace('0', '+84')

      let response = await getUserByPhone(cookies.lottmekong, phone)

      if (response) {
        let isAvailable = !response.FirstLevelAgent && !response.SecondLevelAgent && !response.Collaborator;

        if (isAvailable) {
          props.setUser(response.id)
        } else {
          props.setUser(null)
        }
        response.isAvailable = isAvailable
        setDetectedUser(response)
      }
    } else if (evt.target.value.length > 10) {
      return false
    }
  }

  const getAgent = async (evt) => {
    let keyword = evt.target.value
    if (keyword.length === 6) {

      let response = await findAgent(cookies.lottmekong, 2, keyword)

      if (response && response.data) {
        props.setSecondAgent(response.data.id)
        setDetectedSecondAgentByPhone(null)
        setDetectedSecondAgent(response.data)
      } else {
        props.setSecondAgent('')
      }
    }
    return false
  }

  const getAgentByPhone = async (evt) => {
    let keyword = evt.target.value
    if (keyword.length === 10) {
      keyword = evt.target.value

      let response = await findAgent(cookies.lottmekong, 1, keyword)

      if (response && response.data) {
        props.setSecondAgent(response.data.id)

        setDetectedSecondAgent(null)
        setDetectedSecondAgentByPhone(response.data)
      } else {
        props.setSecondAgent('')
      }
    }
    return false
  }

  return (
    <Form>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Số điện thoại</Form.Label>
            <Form.Control required type="text" placeholder="Nhập số điện thoại" onChange={getUser} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {detectedUser &&
          <Col md={12} className="mb-2">
            <Alert variant={detectedUser.isAvailable ? 'success' : 'danger'}>
              {detectedUser.name} - {detectedUser.phone.replace('+84', '0')} <br /> {detectedUser.FirstLevelAgent ? 'Đại lý cấp 1' : detectedUser.SecondLevelAgent ? 'Đại lý cấp 2' : detectedUser.Collaborator ? 'Khách hàng đại lý' : 'Khách hàng tự do'}
            </Alert>
          </Col>
        }
      </Row>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Số điện thoại đại lý cấp 2</Form.Label>
            <Form.Control required type="text" placeholder="Nhập số điện thoại đại lý cấp 2" onChange={getAgentByPhone} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {detectedSecondAgentByPhone &&
          <Col md={12} className="mb-2">
            <Alert variant={'success'}>
              {detectedSecondAgentByPhone.user.name} - {detectedSecondAgentByPhone.user.phone.replace('+84', '0')}
            </Alert>
          </Col>
        }
      </Row>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Mã đại lý cấp 2</Form.Label>
            <Form.Control required type="text" placeholder="Nhập mã đại lý cấp 2" onChange={getAgent} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {detectedSecondAgent &&
          <Col md={12} className="mb-2">
            <Alert variant={'success'}>
              {detectedSecondAgent.user.name} - {detectedSecondAgent.user.phone.replace('+84', '0')}
            </Alert>
          </Col>
        }
      </Row>
    </Form>
  );
};

import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFileAlt, faSignOutAlt, faTable, faTimes, faHome, faStepBackward, faTransgender } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const permission = localStorage.getItem('permission')

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.Overview.path}>
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Hi, Jane</h6>
                  <Button as={Link} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              {permission === '11' &&
                <NavItem title="Quay về" link={Routes.Overview.path} icon={faStepBackward} />
              }

              <NavItem title="Trang chủ" link={Routes.VietlottOverview.path} icon={faHome} />

              <CollapsableNavItem eventKey="fund/" title="Nạp tiền" icon={faTable}>
                <NavItem title="Nạp vào ví" link={Routes.AddCharge.path} />
                <NavItem title="Lịch sử nạp tiền" link={Routes.ChargeHistory.path} />
                <NavItem title="Lỗi nạp tiền" link={Routes.IssueCharge.path} />
                <NavItem title="Thu hồi" link={Routes.Refund.path} />
                <NavItem title="Lịch sử thu hồi" link={Routes.RefundHistory.path} />
              </CollapsableNavItem>

              <Dropdown.Divider className="my-3 border-indigo" />

              <CollapsableNavItem eventKey="management/" title="Quản lý" icon={faFileAlt}>
                <NavItem title="Đơn hàng" link={Routes.Cart.path} />
                <NavItem title="Vé" link={Routes.Ticket.path} />
                <NavItem title="Vé nhân viên hủy" link={Routes.IssueTicket.path} />
                <NavItem title="Yêu cầu rút tiền" link={Routes.WithdrawRequest.path} />
                <NavItem title="Rút tiền bị từ chối" link={Routes.Withdraw.path} />
              </CollapsableNavItem>

              <Dropdown.Divider className="my-3 border-indigo" />

              <CollapsableNavItem eventKey="statistics/" title="Thống kê" icon={faBook}>
                <NavItem title="Ngân hàng" link={Routes.BankSummary.path} />
                <NavItem title="Ví & cổng TT" link={Routes.EwalletAndPaymentGatewaySummary.path} />
                <NavItem title="Thanh toán" link={Routes.TransactionSummary.path} />
                <NavItem title="Vé mới" link={Routes.RevenueByWaitingTicket.path} />
                <NavItem title="Vé đã in" link={Routes.RevenueByPrintedTicket.path} />
                <NavItem title="Vé đã hoàn thành" link={Routes.RevenueByDoneTicket.path} />
                <NavItem title="Vé đã hủy" link={Routes.DeletedTicket.path} />
                <NavItem title="Hoàn tiền" link={Routes.CashBack.path} />
                <NavItem title="Nạp tiền" link={Routes.ChargeSummary.path} />
                <NavItem title="Rút tiền" link={Routes.WithdrawSummary.path} />
                <NavItem title="K/H mới đăng ký" link={Routes.NewUserSummary.path} />
              </CollapsableNavItem>

              <Dropdown.Divider className="my-3 border-indigo" />

              <CollapsableNavItem eventKey="reward/" title="Trúng thưởng" icon={faBook}>
                <NavItem title="Trúng thưởng nhỏ" link={Routes.Reward.path} />
                <NavItem title="Trúng thưởng lớn" link={Routes.BigReward.path} />
              </CollapsableNavItem>

              <Dropdown.Divider className="my-3 border-indigo" />

              <CollapsableNavItem eventKey="result/" title="Kết quả" icon={faFileAlt}>
                <NavItem title="Keno" link={Routes.KenoResult.path} />
                <NavItem title="Power 6/55" link={Routes.PowerResult.path} />
                <NavItem title="Mega 6/45" link={Routes.MegaResult.path} />
                <NavItem title="Max3D" link={Routes.Max3dResult.path} />
                <NavItem title="Max3D Pro" link={Routes.Max3dProResult.path} />
              </CollapsableNavItem>

              <Dropdown.Divider className="my-3 border-indigo" />

              <CollapsableNavItem eventKey="period/" title="Kỳ quay" icon={faBook}>
                <NavItem title="Ngày nghỉ" link={Routes.DayOff.path} />
                <NavItem title="Keno" link={Routes.KenoPeriod.path} />
                <NavItem title="Power 6/55" link={Routes.PowerPeriod.path} />
                <NavItem title="Mega 6/45" link={Routes.MegaPeriod.path} />
                <NavItem title="Max3D" link={Routes.Max3dPeriod.path} />
                <NavItem title="Max3D Pro" link={Routes.Max3dProPeriod.path} />
              </CollapsableNavItem>

              <Dropdown.Divider className="my-3 border-indigo" />

              <CollapsableNavItem eventKey="commons/" title="Thông tin chung" icon={faBook}>
                <NavItem title="USB" link={Routes.Devices.path} />
                <NavItem title="Trò chơi" link={Routes.Games.path} />
                <NavItem title="Quảng cáo" link={Routes.Banner.path} />
                <NavItem title="Tài khoản thanh toán" link={Routes.Account.path} />
                <NavItem title="Hình thức thanh toán" link={Routes.PaymentMethod.path} />
                <NavItem title="Ngân hàng" link={Routes.Bank.path} />
                <NavItem title="Thông báo" link={Routes.Notification.path} />
                <NavItem title="Điều khoản sử dụng" link={Routes.Privacy.path} />
                <NavItem title="Liên hệ" link={Routes.Contact.path} />
                <NavItem title="Bảo trì" link={Routes.Maintained.path} icon={faTransgender} />
              </CollapsableNavItem>

              <Dropdown.Divider className="my-3 border-indigo" />

              <CollapsableNavItem eventKey="documentation/" title="Tài khoản" icon={faBook}>
                <NavItem title="Đại lý cấp 1" link={Routes.FirstAgent.path} />
                <NavItem title="Đại lý cấp 2" link={Routes.SecondAgent.path} />
                <NavItem title="Khách hàng đại lý" link={Routes.Collaborators.path} />
                <NavItem title="Khách hàng" link={Routes.User.path} />
                <NavItem title="Nhân viên" link={Routes.Employee.path} />
              </CollapsableNavItem>
              <Dropdown.Divider className="my-3 border-indigo" />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

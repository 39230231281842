import axios from 'axios'
import { host } from '../../helper/config'
import { ITEM_PER_PAGE } from '../../helper/helper'

const PLUGINS_NAME = 'admin-agents'

export const findAgent = async (token, agentLevel, keyword) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/find-agent`, {
        agentLevel: agentLevel,
        keyword: keyword
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data
    }
    return null
}

export const getAllFirstAgent = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-first-agent`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}
export const getAllSecondAgent = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-second-agent`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}
export const getAllCollaborator = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-collaborator`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const addFirstAgent = async (token, code, name, userId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/add-first-agent`, {
        referCode: code,
        name: name,
        user: userId,
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const addSecondAgent = async (token, code, name, userId, firstLevelAgentId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/add-second-agent`, {
        firstLevelAgent: firstLevelAgentId,
        referCode: code,
        name: name,
        user: userId,
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const addCollaborator = async (token, name, userId, secondLevelAgentId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/add-collaborator`, {
        secondLevelAgent: secondLevelAgentId,
        name: name,
        user: userId,
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const deleteAgent = async (token, code, level) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/delete-agent`, {
        code: code,
        level: level,
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.status === 200
    }
    return false
}

import { Button } from '@themesberg/react-bootstrap';
import React from 'react'

export const TableRow = (props) => {
  const { ticket, user, netValue, tax, fee, grossValue } = props.data;

  return (
    <tr>
      <td className="text-center">
        <Button onClick={() => props.onShowDetailPress(props.data)}>
          Chi tiết
        </Button>
      </td>
      <td>
        {(user && user.name) || ""}<br />
        <span><strong className={`text-primary`}>{(user && user.phone) || ""}</strong></span>
      </td>
      <td className='text-center'>
        {ticket.ClassicLotteryChannel.name}<br />
        {ticket.ClassicResult.resultDate}
      </td>
      <td className="text-center">{new Intl.NumberFormat('vn-VI', {}).format(grossValue)}</td>
      <td className="text-center text-danger">{new Intl.NumberFormat('vn-VI', {}).format(tax)}</td>
      <td className="text-center text-warning">{new Intl.NumberFormat('vn-VI', {}).format(fee)}</td>
      <td className="text-center text-success">{new Intl.NumberFormat('vn-VI', {}).format(netValue)}</td>
    </tr>
  );
};
import React, { useRef, useState } from "react";
import { Button, Modal, Toast } from '@themesberg/react-bootstrap';
import { UpdateGuideForm } from "./partials/UpdateGuideForm";
import { useCookies } from "react-cookie";
import { updateGuide } from "../../../../../controller/api/game";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [error, setError] = useState(false)

  const contentRef = useRef(props.guide ? props.guide.content : '')

  const onContentChange = (text) => {
    contentRef.current = text
  }

  const validateUpdateGuide = () => {
    // if (accountRef.current > 0 && valueRef.current.length > 0 && detectedUser) {
    //   return true
    // } else {
    //   return false
    // }

    return true
  }

  const onSubmit = async () => {
    try {
      if (validateUpdateGuide()) {
        const guideId = props.guide ? props.guide.id : 0

        const resp = await updateGuide(cookies.lottmekong, props.game, guideId, contentRef.current)
        
        if (resp) {
          window.location.reload()
        } else {
          alert('Có lỗi xảy ra')
          return false
        }
      } else {
        setError(true)
      }
    } catch (e) {
      alert('error')
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Hướng dẫn chơi {props.name || ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Toast className="d-inline-block m-1" onClose={() => setError(false)} show={error} delay={3000} autohide bg='danger' key={3} >
          <Toast.Header>
            <strong className="me-auto">Lỗi</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>Không thêm được</Toast.Body>
        </Toast>
        <UpdateGuideForm
          setContent={onContentChange}
          content={props.guide ? props.guide.content : ''}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
        <Button variant="primary" onClick={onSubmit}>Lưu</Button>
      </Modal.Footer>
    </Modal>
  );
}

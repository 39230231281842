import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { confirmInput, deleteInput } from '../../../../controller/api/__classic__/input';

export const TableRow = (props) => {
  const { amount, id, ClassicResult, inputDate, cookie } = props;

  const linkTo = `/classic/ticket-storage?inputId="${id}"`

  const [status, setState] = useState(props.status)

  const onConfirmInput = async () => {
    const response = await confirmInput(cookie, id)

    if (response) {
      setState(1)
      window.location.reload()
    }
  }

  const onDeleteInput = async () => {
    const confirmed = window.confirm(`Bạn muốn xóa kỳ nhập này không?`)
    if (confirmed) {
      const response = await deleteInput(cookie, id)

      if (response) {
        setState(1)
        window.location.reload()
      }
    }
  }

  return (
    <tr className='text-center'>
      <td>
        {
        ClassicResult.map(it => {
          let total = 0;
          for (let index = 0; index < it.ClassicLotteryStorage.length; index++) {
            const element = it.ClassicLotteryStorage[index];
            total += element.inputAmount;
          }
          if(total > 0 && amount === 0 && status === 0)
          {
          return <>
            <Button variant='warning' onClick={onConfirmInput}>
              Xong
            </Button>&nbsp;&nbsp;
            <Button variant='warning' onClick={onDeleteInput}>
            Xóa
          </Button>
          </>
          }
          if(amount === 0){
            return <><Button variant='warning' onClick={onDeleteInput}>
            Xóa
          </Button></>
          }
          else{
            return <></>
          }      
        })} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button>
          <Link
            target={'_blank'}
            style={{ color: '#fff', marginLeft: 5 }}
            to={linkTo}
            key={id}
          >
            {'Xem vé'}
            <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
          </Link>
        </Button>
      </td>
      <td>
        {ClassicResult.map(it => {
          let total = 0;
          for (let index = 0; index < it.ClassicLotteryStorage.length; index++) {
            const element = it.ClassicLotteryStorage[index];
            total += element.inputAmount
          }

          return <>
            {it.ClassicLotteryChannel.name} {it.resultDate}: <strong>{total} vé</strong><br />
          </>
        })}
      </td>
      <td>{amount}</td>
      <td style={{ width: 250 }}>
        {status === 0 ? 'Đang nhập' : 'Đã nhập'}
      </td>
      <td>{moment(inputDate || '').format('DD/MM/YYYY HH:mm').toLocaleString()}</td>
    </tr>
  );
};
import React from 'react'
import { Button, Col, Row } from '@themesberg/react-bootstrap'
import { disableTicketRoll, enableTicketRoll } from '../../../../controller/api/systemApi';
import { useCookies } from 'react-cookie';

export const ResultItem = ({ isRollEnabled, listResult }) => {

  const [cookies] = useCookies(['lottmekong']);

  const onEnableClick = () => {
    enableTicketRoll(cookies.lottmekong, '', true)
  }

  const onDisableClick = () => {
    disableTicketRoll(cookies.lottmekong, '', true)
  }

  let sum = 0

  for (let index = 0; index < listResult.length; index++) {
    const element = listResult[index];

    sum += element.amount

  }

  return (
    <div className="shadow-md p-2 mb-4" style={{ backgroundColor: '#fff' }}>
      <div style={{ fontSize: 15 }}>
        <Row>
          {isRollEnabled ?
            <Col>
              <Button variant="danger" size="sm" onClick={onDisableClick}>Tắt chia vé</Button>
            </Col>
            :
            <Col>
              <Button variant="success" size="sm" onClick={onEnableClick}>Bật chia vé</Button>
            </Col>
          }
          <Col>
            <b>Vé KT đã bán</b>
          </Col>
          <Col className='text-right'>
            <b>Tổng cộng; {sum}</b>
          </Col>
        </Row>
      </div>
      <hr className='m-0 mt-2 mb-3' />
      <div>
        {listResult.map((it) => {
          return (
            <Row>
              <Col>
                <b>{it.channelName}</b>
              </Col>
              <Col className='text-right'>
                <b>{it.amount}</b>
              </Col>
            </Row>
          )
        })}
      </div>
    </div>
  );
};
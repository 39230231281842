import React, { useContext } from 'react'
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap'
import { InternalTableRow } from '../component/InternalTableRow'
import Pagination from '../../../../components/Pagination'
import { Context } from '../WithdrawSummary'

const _Table = () => {

  const { state, dispatch } = useContext(Context);

  return (
    <Card border="light" className="shadow-sm">
      <Table responsive className="align-items-center table-bordered table-hover">
        <thead className='text-center'>
          <tr>
            <th scope="col">Người chơi</th>
            <th scope="col">Số tiền</th>
            <th scope="col">Thời gian</th>
          </tr>
        </thead>
        <tbody>
          {state.data.internal.list && state.data.internal.list.map((pv, index) => <InternalTableRow rIndex={index} key={`table-keys-lottmekong-${pv.id}`} {...pv} />)}
        </tbody>
      </Table>
      <Card.Footer className='flex-row'>
        <Row className="align-items-center">
          <Col>
            <Pagination
              totalPage={state.data.internal.totalPage || 0}
              currentPage={state.filter.internalPage || 0}
              onPageChange={(i) => {
                dispatch({
                  type: 'UPDATE_PAGE_INTERNAL',
                  payload: i
                })
              }}
            />
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default React.memo(_Table)
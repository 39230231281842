import React from 'react'

export const TableRow = (props) => {
  const { ClassicResult, amount, ClassicLotteryChannel, ClassicLotteryStorage, status, rewardAmount } = props;

  let statusName = 'Không trúng'

  switch (status) {
    case 0:
      statusName = 'Đang xử lý'
      break;
    case 4:
      statusName = 'Trúng thưởng'
      break;
    default:
      break;
  }

  if (status === 1) {
    statusName = 'Đã mua'
  } else

    return (
      <tr>
        <td>
          {ClassicLotteryChannel.name}<br />
          {ClassicResult.resultDate}
        </td>
        <td className="align-items-right text-center">{ClassicLotteryStorage.numbers.split('').join(' ')}</td>
        <td className="align-items-right text-center">{statusName}</td>
        <td className="align-items-right text-center">{new Intl.NumberFormat('vn-VI', {}).format(amount) || ""}</td>
        <td className="align-items-right text-center">{new Intl.NumberFormat('vn-VI', {}).format(rewardAmount) || ""}</td>
      </tr>
    );
};

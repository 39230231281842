import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap'
import { getListClassicTicket } from '../../../../controller/api/__classic__/ticket'
import { useCookies } from 'react-cookie'
import { TableRow } from '../component/TableRow'
import { calculatePage } from '../../../../helper/helper'
import Pagination from '../../../../components/Pagination'
import { Context } from '../Ticket'
import TicketDetail from "../sub-screen/ticket-detail/TicketDetail"
import ImagePreview from '../sub-screen/image-preview/ImagePreview'

const _Table = () => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [data, setData] = useState({})

  const [page, setPage] = useState(0)

  const [modalShow, setModalShow] = useState(false)
  
  const [modalImageShow, setModalImageShow] = useState(false)

  const ClassicResultRef = useRef(null)

  const ticketIdRef = useRef('')

  const ClassicLotteryStorageRef = useRef(null)

  const ClassicChannelRef = useRef(null)
  
  const imagesRef = useRef([])
  
  const rewardsRef = useRef([])
  
  const amountRef = useRef(0)
  
  const selectedImageRef = useRef('')
  
  const previewTitleRef = useRef('')
  
  const statusRef = useRef(0)
  
  const transactionPayRewardRef = useRef(null)

  const rewardNumberRef = useRef('')

  const getAllFunc = useCallback(async () => {
    const response = await getListClassicTicket(cookies.lottmekong, {
      ticketId: state.ticketId,
      cartId: state.cartId,
    }, page)

    if (response) {
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }
      setData(res || {})
    }
  }, [cookies.lottmekong, state.ticketId, state.cartId, page])

  const onShowDetailPress = (ticketId, ClassicLotteryStorage, ClassicResult, ClassicChannel, status, transactionPayReward, rewards, rewardNumber, amount) => {
    amountRef.current = amount
    ticketIdRef.current = ticketId
    ClassicResultRef.current = ClassicResult
    ClassicLotteryStorageRef.current = ClassicLotteryStorage
    ClassicChannelRef.current = ClassicChannel
    statusRef.current = status
    transactionPayRewardRef.current = transactionPayReward
    rewardsRef.current = rewards
    rewardNumberRef.current = rewardNumber
    setModalShow(true)
  }

  const showImagePreview = (title, image) => {
    previewTitleRef.current = title

    selectedImageRef.current = image

    setModalImageShow(true)
  }

  useEffect(() => {
    if (state.cartId !== '' || state.ticketId !== '') {
      getAllFunc()
    }
  }, [getAllFunc, state.cartId, state.ticketId])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th scope="col">Thao tác</th>
              <th scope="col">Mã đơn</th>
              <th scope="col">Mã vé</th>
              <th scope="col">Người chơi</th>
              <th scope="col">Kỳ quay số</th>
              <th scope="col">Dãy số</th>
              <th scope="col">Trạng thái</th>
              <th scope="col">Số lượng</th>
              <th scope="col">Giá trị giải thưởng</th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map((pv, index) => <TableRow onShowDetailPress={onShowDetailPress} rIndex={index} key={`table-keys-lottmekong-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={data.totalPage || 0}
                currentPage={page || 0}
                onPageChange={(i) => {
                  setPage(i)
                }}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <TicketDetail
        show={modalShow}
        ticket-id={ticketIdRef.current}
        status={statusRef.current}
        storage={ClassicLotteryStorageRef.current}
        result={ClassicResultRef.current}
        channel={ClassicChannelRef.current}
        images={imagesRef.current}
        amount={amountRef.current}
        transactionPayReward={transactionPayRewardRef.current}
        rewards={rewardsRef.current}
        rewardNumber={rewardNumberRef.current}
        handleShowImagePreview={showImagePreview}
        onHide={() => setModalShow(false)}
      />
      <ImagePreview
        show={modalImageShow}
        title={previewTitleRef.current}
        image={selectedImageRef.current}
        onHide={() => setModalImageShow(false)}
      />
    </>
  );
};

export default React.memo(_Table)